var contactRegions, contactRbhRegions;
var contactForms = {
	contactGeneralEnquiry: ['howDidYouHear'],
	contactLivingPlantation: ['requestType', 'address'],
	contactBuildingPlantation: ['jobNumber'],
	lookingToBuildBuy: ['enquiry', 'region', 'howDidYouHear', 'subscribe'],
}

$(document).ready(function() {
	// gtm
	function gtmTrigger(data) {
		dataLayer.push(data);
	}
	
	$('.video-popup-link').magnificPopup({
	      type: 'iframe',
	      iframe: {
	       markup: '<div class="mfp-iframe-scaler">' +
	                  '<div class="mfp-close"></div>' +
	                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
	                  '</div>' +
	                  '<div class="mfp-bottom-bar">' +
	                  '<div class="mfp-title"></div>' +
	                  '</div>',
	          patterns: {
	              youtube: {
	                  index: 'youtube.com/', 
	                  id: function(url) {        
	                      var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
	                      if ( !m || !m[1] ) return null;
	                      return m[1];
	                  },
	                  src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
	              },
	              vimeo: {
	                  index: 'vimeo.com/', 
	                  id: function(url) {        
	                      var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
	                      if ( !m || !m[5] ) return null;
	                      return m[5];
	                  },
	                  src: '//player.vimeo.com/video/%id%?autoplay=1'
	              }
	          }
	      }
	  });


	// intro popup handler
	$('.hero_block__button:not(.page_link)').on('click', function() {

		var thisID = $(this).attr('id'),
			buttonClickID = thisID.replace('intro-popup', 'hero-popup');

		function openIntroPopup(buttonClickID) {
			$('#' + buttonClickID).click();
			$('html').addClass('no-scroll');
			$('body').addClass('modal-open');
			$('.intro-popup').children('.intro_bottom').fadeOut();
			$('.intro-popup').children('.hero_block').fadeOut();
			$('.hero-popup-shell').fadeIn(function() {
				$(this).addClass('on');
			});
		}

		if ($(window).scrollTop() > 0) {
			$('html, body').animate({
				scrollTop: 0
			}, 600, function() {
				openIntroPopup(buttonClickID);
			});
		}
		else {
			openIntroPopup(buttonClickID);
		}
		return false;
	});
	$('.hero-popup__inside').on('click', 'a.close-popup', function() {
		$('.hero-popup-shell').fadeOut(function() {
			$(this).removeClass('on');
			$('html').removeClass('no-scroll');
			$('body').removeClass('modal-open');
		});
		$('.intro-popup').children('.intro_bottom').fadeIn();
		$('.intro-popup').children('.hero_block').fadeIn();
		return false;
	});

	// intro section navigation arrow - on click scroll to recently viewed section
	$('.intro_bottom__arrow-icon').click(function(){
		var offsetTop;
		if ($('#recently-viewed-section').length > 0) offsetTop = $('#recently-viewed-section').offset().top + 5;
		else if ($('#awards-values-section').length > 0) offsetTop = $('#awards-values-section').offset().top + 5;

		if (typeof offsetTop != 'undefined') {
			$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
		}
	});

	function introSearchForm() {
		if ($('.intro-search-form').length > 0) {
			var $introSearchForm = $('.intro-search-form'),
				$introSectionSlider = $('.intro-section--slider'),
				$navBar = $('.header'),
				introSearchFormHeight = $introSearchForm.outerHeight(),
				introSectionSliderHeight = $introSectionSlider.outerHeight();

			if (
				window_width() <= 767 ||
				(window.matchMedia("(orientation: landscape)").matches && window_width() <= 1024) ||
				window_height() <= 850
				) {
				var newHeight = $(window).height() - $navBar.outerHeight();
			}
			else
				var newHeight = $(window).height() - $navBar.outerHeight() - introSearchFormHeight;

			$('#intro-section').css('height', newHeight + 'px');
			$('.intro-section--slider').css('height', newHeight + 'px');
			$('.intro-section--slider .slick-slide').css('height', newHeight + 'px');
		}
	}

	$('.icon-anchor-link').click(function(e){
		e.preventDefault();
		var datalink = $(this).attr('data-link');
		var offsetTop = $(datalink).offset().top - 100;
		$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
	});

	$('.icon-anchor-link-video').click(function(e){
		e.preventDefault();
		$("#gallerySection .nav-secondary a[data-class='video']").trigger('click');
		var offsetTop = $('#gallerySection').offset().top - 100;
			$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
	});

	var homeRbPopup = getCookie('home-rb-popup');
	if (homeRbPopup != 'hide') {
		$('.hero_block--popup-rb').addClass('open');
	}
	$('.hero_block--popup-rb-close').on('click', function() {
		$('.hero_block--popup-rb').fadeOut();
		createCookie('home-rb-popup', 'hide');
		return false;
	});

	var homeSummerSale = getCookie('home-summer-sale-popup');
	if (!$('.popup-summer-sale').parent().hasClass('intro-section--slider_slide')) {
		if (homeSummerSale != 'hide') {
			$('.popup-summer-sale').addClass('open');
		}
		$('.popup-summer-sale__close').on('click', function() {
			$('.popup-summer-sale').fadeOut();
			createCookie('home-summer-sale-popup', 'hide');
			return false;
		});
	}

	// footer back-to-top button scroll top on click
	$('.back-to-top').click(function(){
		$('html, body').animate({
			scrollTop: 0
		}, 600);
	});

	$('button.footer-top-section__button').on('click', function() {
		var $thisButton = $(this);
		if ($thisButton.attr('data-url') != 'undefined') {
			if ($thisButton.length > 0) {
				window.location = $thisButton.attr('data-url');
			}
		}
		return false;
	});

	// intro home page fade in sticker
	if ($('.intro_bottom_sticker').length > 0) {
		$('.intro_bottom_sticker').delay(2000).fadeIn(1500);
		$('.intro_bottom_sticker--close').on('click', function() {
			$('.intro_bottom_sticker').fadeOut(1500);
			return false;
		})
	}

	$('a.contact-box').on('click', function() {
		if ($(this).attr('href') == '') {
			var headingHTML = $(this).children('.heading').html();
			$('#contactForm').find('.form-enquiry-secondary__aside').children('h3').html(headingHTML);

			if (typeof $(this).attr('data-ajax-call') != 'undefined') {
				$('#contactForm').attr('data-ajax-call', $(this).attr('data-ajax-call'));
			}
			if (typeof $(this).attr('data-form-type') != 'undefined') {
				var formType = $(this).attr('data-form-type'),
					formPageID = $(this).attr('data-form-page-id');
				$('#contactForm').find('#send_to').val(formType);
				if (typeof formPageID != 'undefined' && formPageID !== false) {
					$('#contactForm').find('#form_page_id').val(formPageID);
				}
			}

			// sort out which fields to expose to the form
			$('#contactForm').find('.toggleable').hide();
			if (typeof $(this).attr('data-form-type') != 'undefined') {
				var formType = $(this).attr('data-form-type');
				if (typeof contactForms[formType] != 'undefined') {
					var exposeClasses = contactForms[formType];
					if (exposeClasses.length > 0) {
						for (i in exposeClasses) {
							exposeThisClass = exposeClasses[i];
							$('.toggleable-' + exposeThisClass).show();
						}
					}
				}
			}
			$('#modal-contact-form').modal();
			return false;
		}
	});

	$('#modal-enquiry-form').on('change', 'select', function() {
		if($(this).attr('id') != 'field-region') {
			$(this).css('color','#231f20');
		}
	});

	$('#modal-enquiry-form').on('change', '#field-region', function() {
		if($(this).find('option:selected').text() !=''){
			$(this).css('color','#231f20');
		}
	});

	$('.ctaForm').on('change', '#field-describes_you', function() {
		if($(this).find('option:selected').text() !=''){
			$(this).css('color','#231f20');
		}
	});

	$("#contactForm").on('change', 'select', function() {
		if ($(this).find('option:selected').attr('value') != '') $(this).css('color', '#231f20');
		else $(this).css('color', '');

		if ($(this).attr('id') == 'field-region' && $('#field-sales-centre').length > 0) {
			if ($('#field-sales-centre').val() != '') $('#field-sales-centre').css('color', '#231f20');
			else $('#field-sales-centre').css('color', '');
		}
	});

	$(".nature-enquiry-select").change(function() {
   		 $('.describes_select').hide();
   		 if($(".nature-enquiry-select option:selected").text() == "Ready Built"){
	   		 $('#readybuilt_select').show();
   		 } else {
	   		 $('#non_readybuilt_select').show();
   		 }
   		 if($(".nature-enquiry-select option:selected").text() == "Dual Occupancy"){
   		 	$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').hide();
			$('#contactForm .dual_occupancy_warning').removeClass('inactive');

   		}

	});

	$(".booking_nature_of_enquiry").change(function(e) {
   		if($(".booking_nature_of_enquiry option:selected").text() == "Dual Occupancy"){
			$('.dual_occupancy_warning').removeClass('hidden');
   		}
	});

	$(".dual_dimiss").click(function() {
			$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').show();
			$('#contactForm .dual_occupancy_warning').addClass('inactive');
			$(".nature-enquiry-select").find('option:eq(0)').prop('selected', true);

	});
	$(".book_dual_dimiss").click(function(e) {
			e.preventDefault();
			$('.dual_occupancy_warning').addClass('hidden');
			$(".booking_nature_of_enquiry").find('option:eq(0)').prop('selected', true);

	});


	if($('body').hasClass('estate')){
		salespersonPhone = $('.estate_contact').attr('data-salesperson-phone');
		$('.estate_contact').attr('href', 'tel:+61' + salespersonPhone.replace(/\s|\-|\.|\(|\)/g, '').replace(/^0/, ''));
	}

	if($('body').hasClass('display-centre-single')){
		salespersonPhone = $('.estate_contact').attr('data-salesperson-phone');
		$('.estate_contact').attr('href', 'tel:+61' + salespersonPhone.replace(/\s|\-|\.|\(|\)/g, '').replace(/^0/, ''));
	}

	$('a.tour-activate').on('click', function(e) {
		e.preventDefault();

		$('.tour-iframe').attr('src','').css('display', 'none');

		var theid = $(this).attr('data-id');
		var theLink = $(this).attr('rel');

		$('.tour-activate').show();
		$('#link-'+theid).css('display', 'none');
		$('#tourframe-'+theid).css('display', 'block').attr('src',theLink);

	});

	// bootstrap modal interaction improvements
	$('body').on('click', '.modal-backdrop', function() {
		// dismiss modal on backdrop click
		var $thisModal = $('.modal.in');
		if (typeof $thisModal != 'undefined') {
			if ($thisModal.is(':visible')) {
				$thisModal.find('.modal-close').trigger('click');
			}
		}
		return false;
	}).on('keyup', function(event) {
		if (event.keyCode == 27) {
			// dismiss modal on esc key press
			var $thisModal = $('.modal.in');
			if (typeof $thisModal != 'undefined') {
				if ($thisModal.is(':visible')) {
					$thisModal.find('.modal-close').trigger('click');
				}
			}
			return false;
		}
	});

	$('.ctaForm').on('change', 'select', function() {
		$(this).css('color','#231f20');
	});

	// populate any region / sales centre dropdowns
	if ($('.field-region').length > 0) {
		$.ajax({
			type: 'POST',
			url: '/ajax/contact_get_dropdowns.php',
			data: ({ action: 'regions' }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					// if (response.result.length > 0) {
					if (Object.keys(response.result).length > 0) {
						// reset the region and sales centre dropdowns
						$('.field-region').each(function() {
							$(this).find('option').remove().end().append('<option value="" selected>Select your region *</option>');
						});

						$('.field-sales-centre').each(function() {
							var defaultText = "Select your display centre *",
								siblingLabel = $(this).parent().siblings('label').html();
							if (typeof siblingLabel == 'string') defaultText = siblingLabel;

							$(this).find('option').remove().end().append('<option value="" selected>' + defaultText + '</option>');
						});

						if ($('body').hasClass('readybuilt')) {
							for (i in response.result.readybuilt) { // readybuilt regions
								thisRegion = response.result.readybuilt[i];
								$(".field-region").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.name + '</option>');
								});
							}
						}
						else {
							for (i in response.result.plantation) { // plantation regions
								thisRegion = response.result.plantation[i];
								$(".field-region").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.name + '</option>');
								});
							}
						}
						contactRegions = response.result.plantation;
						contactRbhRegions = response.result.readybuilt;

						if ($('body').hasClass('house-and-land')) {
							$('.field-region').each(function() {
								if (typeof $(this).attr('data-region') != 'undefined') {
									var selectedRegion = $(this).attr('data-region');
									$(this).val(selectedRegion);
									$(this).trigger('change');
								}
							});
						}
					}

					$('#contactForm').on('change', '#field-enquiry', function() {
						var theseRegions;
						if ($(this).val() == 'Ready Built') theseRegions = contactRbhRegions;
						else theseRegions = contactRegions;
						// theseRegions = contactRegions;

						$('.field-region').each(function() {
							$(this).find('option').remove().end().append('<option value="" selected>Select your region *</option>');
							for (i in theseRegions) {
								var currentRegion = theseRegions[i];
								$(this).append('<option value="' + currentRegion.name + '">' + currentRegion.name + '</option>');
							}
							$(this).trigger('change');
						});
					});
				}
			}
		});
	}


	$('.field-covid-location').on('change', function() {
		var thisRegion = $(this).val();
		console.log(thisRegion);
		$.ajax({
			type: 'POST',
			url: '/ajax/get_consumer_health_meeting.php',
			data: ({ location: thisRegion }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					$('#meeting_type_display').show().css('display','block');
					$('#meeting_type_agent').hide().css('display','none');
					console.log(response.result);
					// if (response.result.length > 0) {
					if (Object.keys(response.result).length > 0) {
						console.log(response.result);
						// reset the region and sales centre dropdowns
						$('.field-covid-meeting').each(function() {
							$(this).find('option').remove().end().append('<option value="" selected>Select your Meeting Type *</option>');
						});



						for (i in response.result) { // readybuilt regions
							thisRegion = response.result[i];
							$(".field-covid-meeting").each(function() {
								$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.label + '</option>');
							});
						}

					}


				} else {

					$('#meeting_type_display').hide();
				}
			}
		});


	});


	$('.field-covid-trade-location').on('change', function() {
		var thisRegion = $(this).val();

		$.ajax({
			type: 'POST',
			url: '/ajax/get_trade_health_meeting.php',
			data: ({ location: thisRegion }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {

					if(thisRegion =='Construction'){
						$('#trade_meeting_type_display').show().css('display','block');
						$('#trade_meeting_type_agent').hide().css('display','none');
						// if (response.result.length > 0) {
						if (Object.keys(response.result).length > 0) {
							// reset the region and sales centre dropdowns
							$('.field-covid-trade-meeting').each(function() {
								$(this).find('option').remove().end().append('<option value="" selected>Select Site Supervisor *</option>');
							});

							for (i in response.result) { // readybuilt regions
								thisRegion = response.result[i];
								$(".field-covid-trade-meeting").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.label + '</option>');
								});
							}

						}


					}

					if(thisRegion =='Warranty'){
						$('#trade_meeting_type_display').hide().css('display','none');
						$('#trade_meeting_type_agent').show().css('display','block');
						// if (response.result.length > 0) {
						if (Object.keys(response.result).length > 0) {
							console.log(response.result);
							// reset the region and sales centre dropdowns
							$('.field-covid-trade-agent').each(function() {
								$(this).find('option').remove().end().append('<option value="" selected>Select Warranty Inspector *</option>');
							});



							for (i in response.result) { // readybuilt regions
								thisRegion = response.result[i];
								$(".field-covid-trade-agent").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.label + '</option>');
								});
							}

						}

					}




				} else {

					$('#trade_meeting_type_agent').hide().css('display','none');
					$('#trade_meeting_type_display').hide().css('display','none');
				}
			}
		});


	});

	$('.field-covid-meeting').on('change', function() {
		var thisRegion = $(this).val();
		if(thisRegion =='Plantation Homes Head Office|Meeting a Plantation Homes Team Member'){
			$('#meeting_type_agent_text').show().css('display','block');
			$('#recipients').val('aleech@plantationhomes.com.au, cives@plantationhomes.com.au, hstehling@plantationhomes.com.au, rreception@henley.com.au');
		}
		else {
			$.ajax({
				type: 'POST',
				url: '/ajax/get_consumer_health_agent.php',
				data: ({ meeting: thisRegion }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {

						// if (response.result.length > 0) {
						if (Object.keys(response.result).length > 0) {


							if(response.result[0].meeting =='NULL'){
								$('#meeting_type_agent').hide().css('display','none');
								$('#recipients').val(response.result[0].recipients);

							}
							else {
								$('#meeting_type_agent').show().css('display','block');

								// reset the region and sales centre dropdowns
								$('.field-covid-agent').each(function() {
									$(this).find('option').remove().end().append('<option value="" selected>Select Meeting With *</option>');
								});

								for (i in response.result) { // readybuilt regions
									thisRegion = response.result[i];
									$(".field-covid-agent").each(function() {
										$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.label + '</option>');
									});
								}

							}

						}


					} else {

						$('#meeting_type_agent').hide();
					}
				}
			});
		}

	});




	$('.field-covid-agent').on('change', function() {
		var thisRegion = $(this).val();
		$.ajax({
			type: 'POST',
			url: '/ajax/get_consumer_health_emails.php',
			data: ({ agent: thisRegion }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					console.log(response.result[0].recipients);
					if (Object.keys(response.result).length > 0) {
						$('#recipients').val(response.result[0].recipients);
					}

				}
			}
		});


	});

	$('.field-covid-trade-meeting, .field-covid-trade-agent').on('change', function() {
		var thisRegion = $(this).val();
		$.ajax({
			type: 'POST',
			url: '/ajax/get_trade_health_emails.php',
			data: ({ agent: thisRegion }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {

					// if (response.result.length > 0) {
					if (Object.keys(response.result).length > 0) {

						$('#recipients').val(response.result[0].recipients);

					}

				}
			}
		});


	});




	$('.field-region').on('change', function() {
		var thisRegion = $(this).val(),
			enquiringAbout = $('#field-enquiry').val(),
			$salesCentre = $('.field-sales-centre');

		if (enquiringAbout == 'Ready Built' || $('body').hasClass('readybuilt')) {
			$salesCentre.each(function() {
				var defaultText = "Select your display centre *",
					siblingLabel = $(this).parent().siblings('label').html();
				if (typeof siblingLabel == 'string') defaultText = siblingLabel;
				$(this).find('option').remove().end().append('<option value="" selected>' + defaultText + '</option>');

				for (i in contactRbhRegions) {
					var currentRegion = contactRbhRegions[i];
					if (currentRegion.name == thisRegion) {
						$salesCentre.find('option').remove().end().append('<option value="" selected>' + defaultText + '</option>');
						for (j in currentRegion.salesCentres) {
							currentSalesCentre = currentRegion.salesCentres[j];
							$salesCentre.append('<option value="' + currentSalesCentre + '">' + currentSalesCentre + '</option>');
						}
					}
				}
			});
		}
		else {
			$salesCentre.each(function() {
				var defaultText = "Select your display centre *",
					siblingLabel = $(this).parent().siblings('label').html();
				if (typeof siblingLabel == 'string') defaultText = siblingLabel;
				$(this).find('option').remove().end().append('<option value="" selected>' + defaultText + '</option>');

				for (i in contactRegions) {
					var currentRegion = contactRegions[i];
					if (currentRegion.name == thisRegion) {
						$salesCentre.find('option').remove().end().append('<option value="" selected>' + defaultText + '</option>');
						for (j in currentRegion.salesCentres) {
							currentSalesCentre = currentRegion.salesCentres[j];
							$salesCentre.append('<option value="' + currentSalesCentre + '">' + currentSalesCentre + '</option>');
						}
					}
				}
			});
		}
	});
		// populate the contact form fields
	if ($('#contactForm').length > 0) {
		$('#contactForm').on('change', 'input[name=request_type]', function() {
			var requestType = $(this).val();
			$('.toggleable-general-maintenance-blurb, .toggleable-urgent-maintenance-blurb, .toggleable-general-urgent-maintenance').hide();
			$('.toggleable-image-upload').hide();

			if (requestType == 'Maintenance') {
				$('.toggleable-maintenance').show();
				$('.toggleable-image-upload').show();

				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', true);
				});

				if ($('input[name=maintenance_request]:checked').val() == "A general maintenance issue") {
					$('.toggleable-general-maintenance-blurb, .toggleable-general-urgent-maintenance').show();
					$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
						$(this).prop('disabled', false);
					});
				}
				if ($('input[name=maintenance_request]:checked').val() == "Requiring urgent attention") {
					$('.toggleable-urgent-maintenance-blurb, .toggleable-general-urgent-maintenance').show();
					$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
						$(this).prop('disabled', false);
					});
				}
			}
			else {
				$('.toggleable-maintenance').hide();
				$('.toggleable-image-upload').hide();

				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
			}
		});
		$('#contactForm').on('change', 'input[name=maintenance_request]', function() {
			var maintenanceType = $(this).val();
			if (maintenanceType == 'A general maintenance issue') {
				$('.toggleable-general-maintenance-blurb').show();
				$('.toggleable-urgent-maintenance-blurb').hide();
				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
				$('.toggleable-general-urgent-maintenance, .toggleable-image-upload').show();
			}
			else if (maintenanceType == 'Requiring urgent attention') {
				$('.toggleable-general-maintenance-blurb').hide();
				$('.toggleable-urgent-maintenance-blurb').show();
				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
				$('.toggleable-general-urgent-maintenance, .toggleable-image-upload').show();
			}
		});

		$('#contactForm').on('change', '.form-enquiry-secondary__input-file', function(e) {
			var $input = $(this),
				label = $input.next(),
				fileName = '',
				$filesLabel = $input.siblings('.form-enquiry-secondary__input-file-label');
			if ($input[0].files && $input[0].files.length > 1) fileName = ($input.attr('data-multiple-caption') || '').replace('{count}', $input[0].files.length);
			else fileName = e.target.value.split('\\').pop();

			if (fileName) {
				$filesLabel.html(fileName);
			}
			else {
				$filesLabel.html('');
			}
		});

		$('#contactForm').validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).parents('.form-enquiry-secondary__field-container').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).parents('.form-enquiry-secondary__field-container').removeClass('error');
			},
			errorPlacement: function(error, element) {

			},
			submitHandler: function(form) {
				var ajaxURL = '/ajax/' + $('#contactForm').attr('data-ajax-call') + '.php',
					$submitButton = $('#contactForm .form-enquiry-secondary__actions input[type=submit]'),
					submitValue = $submitButton.val();
				$submitButton.prop('disabled', true).val('Submitting...');
				fadeLoader('in');
				$('#contactForm').ajaxSubmit({
					type: 'POST',
					url: ajaxURL,
					dataType: 'json',
					traditional: true,
					filtering: function(el, index) {
						if($(el).parent().is(':visible')) {
							return el;
						}
						if($(el).attr('id') == 'send_to') {
							return el;
						}
					},
					success: function(response) {
						$('#modal-contact-form').addClass('small-modal');
						fadeLoader('out');
						$submitButton.prop('disabled', false).val(submitValue);
						if (response.status == 'ok') {
							$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').hide();
							$('#contactForm .form-enquiry-secondary__success').not('.dual_occupancy_warning').removeClass('inactive');

							// push to GTM
							dataLayer.push({
								formType: $('#send_to').val(),
								formId: '#contactForm',
								event: 'formSubmissionSuccess'
							});
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});
		$('#modal-contact-form').on('hidden.bs.modal', function() {
			// reset the form
			$('#contactForm')[0].reset();
			// clear out any errors
			$('#contactForm').find('.error').removeClass('error');
			$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').show();
			$('#contactForm .form-enquiry-secondary__success').addClass('inactive').find('img').remove();
			$('#contactForm #upload-file').prop('disabled', true);
		});
	}

	// enquiry form
	$('.widget-enquiry__btn, .call_to_action__enquiry').on('click', function() {
		if ($(this).attr('href') == '#' || $(this).attr('href') == '') {
			if ($(this).hasClass('widget-enquiry__btn') && $('body').hasClass('readybuilt')) {
				if ($('body').hasClass('readybuilt_lot')) {
					$('#modal-enquiry-form').modal();
					return false;
				}
				else if ($('body').hasClass('readybuilt_townhomes')) {
					var addressOfEnquiry = $(this).attr('data-address'),
						pageID = $(this).attr('data-page-id');
						salesID = $(this).attr('data-sales-id');
					$('#modal-enquiry-form').find('.form-enquiry-secondary__aside em').html(addressOfEnquiry);
					$('#modal-enquiry-form').find('#page_id').val(pageID);
					$('#modal-enquiry-form').find('#rb_modal_override_sales_centre_id').val(salesID);
					$('#modal-enquiry-form').modal();
					return false;
				}
			}
			else if($(this).parents('.container__widget').length) {
				$('#address_of_enquiry').val('');
				$('#modal-enquiry-form-widget').modal();
			}
			else {
				$('#address_of_enquiry').val('');
				$('#modal-enquiry-form').modal();
				return false;
			}
		}
	});

	$('#inclusionsSection .list-images figure').click(function() {
		var page_id = $(this).data('page-id');
		if (page_id) {
			var slick_index = $('#modal-inclusions .slick-slide:not(.slick-cloned)[data-page-id=' + page_id + ']').data('slick-index');
			if (slick_index != undefined && $('.inclusions .slick-slide.slick-current.slick-active').data('slick-index') != slick_index) {
				$('#modal-inclusions .slick-slider').slick('slickGoTo', slick_index, false);
			}
		}
		$('#modal-inclusions').modal();
		return false;
	});

	$('#inclusionsChooserSection article ul.tier-selector').on('click', 'button', function() {
		var pos = $(this).attr('data-tier-number'),
			$table = $('#inclusionsSelectTable');
		$table.find('tr.collapsible').removeClass('collapsed').addClass('expanded');
		$table.find('tr.grey-bg').addClass('expanded');

		$('#inclusionsChooserSection article ul li.active').removeClass('active').children('button.active').removeClass('active').children('span').html('Select');
		$(this).addClass('active').children('span').html('Selected');
		$(this).parents('li').addClass('active');

		// change highlighted column
		$table.find('td.inclusion-column.active').removeClass('active');
		$table.find('td.inclusion-column-' + pos).addClass('active');
	});

	$('#inclusionsSelectTable').on('click', 'a.table-expand', function() {
		var $collapsibleHeader = $(this).parents('tr.grey-bg');
			$collapsibleRow = $collapsibleHeader.next('.collapsible');
		if ($collapsibleRow.hasClass('collapsed')) {
			$collapsibleHeader.addClass('expanded');
			$collapsibleRow.removeClass('collapsed').addClass('expanded');
		}
		else {
			$collapsibleHeader.removeClass('expanded');
			$collapsibleRow.removeClass('expanded').addClass('collapsed');
		}
		return false;
	});

	$('#enquiryForm').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-enquiry-secondary__field-container').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-enquiry-secondary__field-container').removeClass('error');
		},
		errorPlacement: function(error, element) {

		},
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				ajaxURL = '/ajax/' + $('#enquiryForm').attr('data-ajax-call') + '.php',
				$submitButton = $('#enquiryForm .form-enquiry-secondary__actions input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: ajaxURL,
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).val(submitValue);
					$('#modal-enquiry-form').addClass('small-modal');

					if (response.status == 'ok') {
						dataLayer.push({
							formType: $('#send_to').val(),
							formId: 'enquiryForm',
							enquiringAbout: $('#name_of_series').val(),
							event: 'formSubmissionSuccess'
						});

						$('#enquiryForm .form-enquiry-secondary__content').hide();
						$('#enquiryForm .form-enquiry-secondary__aside').attr('style', 'display: none !important');
						$('#enquiryForm .form-enquiry-secondary__success').not('.dual_occupancy_warning').removeClass('inactive');

					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});
	$('#modal-enquiry-form').on('hidden.bs.modal', function() {
		// reset the form
		$('#enquiryForm')[0].reset();

		var $regionFields;
		$regionFields = $('#enquiryForm').find('#field-region');
		if ($regionFields.length > 0) {
			$regionFields.each(function() {
				var selectedRegion = $(this).attr('data-region');
				$(this).val(selectedRegion);
				$(this).trigger('change');
			});
		}

		// clear out any errors
		$('#enquiryForm').find('.error').removeClass('error');
		$('#enquiryForm .form-enquiry-secondary__aside, #enquiryForm .form-enquiry-secondary__content').show();
		$('#enquiryForm .form-enquiry-secondary__success').addClass('inactive').find('img').remove();
	});

	// sitemap button toggle on click
/*
	$('.sitemap-toggle-btn').click(function(){
		$('.sitemap-wrapper').slideToggle();
		$(this).toggleClass('active');
		return false;
	});
*/

	// heart button animate on click
	$('.action-buttons__favorite').click(function(){
		$(this).addClass('pulse');
		setTimeout(function () {
			$('.action-buttons__favorite').removeClass('pulse');
		}, 500);
	});

	// compare button animate on click
	$('.main-part').on('click', '.action-buttons__compare', function() {
		$(this).addClass('pulse');
		setTimeout(function () {
			$('.action-buttons__compare').removeClass('pulse');
		}, 500);

		var pageID = $(this).parents('.property').attr('data-id'),
			type   = $(this).attr('data-type');
		$.ajax({
			type: 'POST',
			url: '/ajax/add_to_compare.php',
			data: ({ pageID: pageID, type: type }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					$('.compare-tooltip').show();
					$('.fixed-compare').children('.compare-count').html(response.sessionCount);
					$('.fixed-compare').fadeIn();
				}
				else {
					alert(response.message);
				}
			}
		});
		return false;
	});

	$('.li-has-submenu:not(.li-keep-menu-open)').hover(
	  function() {
	    $(this).find('.submenu').stop().slideDown(300);
	  }, function() {
	    $(this).find('.submenu').stop().slideUp(300);
	  }
	);

	// mouse enters mobile nav + links
	$('.expandable-mobile').click(function(e){
		e.preventDefault();

		$(this).toggleClass('active');
		if($(e.target).is('a') && !$(this).hasClass('active')){

			window.location.href = $(e.target).attr('href');

		}
		return false;
	});

	var scrollTo;
	// mobile sidenav open/close
	$('.header__mobile-button').click(function(){
		$('html').toggleClass('menu-active');
		$(this).toggleClass('active');

		// stops page scrolling, there is a slight jump when opened to top of page
		// which we reset once closed.
		if($('html').hasClass('menu-active')){
			scrollTo = $('body').scrollTop();
			//$('body').css("position", "fixed");
		}else{
			//$('body').css("position", "static");
			$('body').animate({scrollTop: scrollTo}, 0);
		}
	});

	var resizeTimeout;
	$(window).resize(function() {
		//mq = window.matchMedia( "(max-width: 992px)" );
		/* If window >992px close sidenav */

		if ($('body').hasClass('book-call-back-landing-page')) {
			var $header = $('header');
			if ($(window).width() < 992) {
				var headerHeight = $header.css('height');
				$header.css('height', 'auto').attr('data-height', headerHeight);
			}
			else {
				$header.css('height', 'auto').attr('data-height', null);
			}
		}

		threeColumnSlider();
	});

	function threeColumnSlider() {
		if ($('.three-columns').length > 0) {
			if (window_width() <= 767) {
				if (!$('.three-columns').hasClass('slick-initialized')) {
					$('.three-columns').slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: false,
						arrows: true,
						dots: true
					});
				}
			}
			else {
				if ($('.three-columns').hasClass('slick-initialized')) {
					$('.three-columns').slick('unslick');
				}
			}
		}
		return true;
	}
	threeColumnSlider();

	if($(".intro-section--slider").length){
		$('.intro-section--slider').on('init', function (event, slick) {
			// render the first image
			var $firstSlide = $('.intro-section--slider .slick-track .slick-slide.slick-current');
			if (typeof $firstSlide.data('geo-bg-srcset') != 'undefined') {
				if ($firstSlide.data('geo-bg-srcset').length > 0) {
					$firstSlide.css('background-image', 'url(' + $firstSlide.data('geo-bg-srcset') + ')');
					$firstSlide.removeData('geo-bg-srcset');
				}
			}
			// process the cloned slides now
			var clonedSlidesBgSrcsets = setTimeout(function() {
				$('.intro-section--slider .slick-track .slick-slide.slick-cloned').each(function (index, thisClonedSlide) {
					$thisClonedSlide = $(thisClonedSlide);
					if (typeof $thisClonedSlide.data('geo-bg-srcset') != 'undefined') {
						if ($thisClonedSlide.data('geo-bg-srcset').length > 0) {
							$thisClonedSlide.css('background-image', 'url(' + $thisClonedSlide.data('geo-bg-srcset') + ')');
							$thisClonedSlide.removeData('geo-bg-srcset');
						}
					}
				});
			}, 5000);
		});
		$('.intro-section--slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			var $thisSlick = $('.intro-section--slider').slick('getSlick');
			var $nextSlide = $($thisSlick.$slides[nextSlide]);
			if (typeof $nextSlide.data('geo-bg-srcset') != 'undefined') {
				if ($nextSlide.data('geo-bg-srcset').length > 0) {
					$nextSlide.css('background-image', 'url(' + $nextSlide.data('geo-bg-srcset') + ')');
					$nextSlide.removeData('geo-bg-srcset');
				}
			}
		});
		$(".intro-section--slider").slick({
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 15000,
			speed: 600
		});
	}

	function addParameter(url, parameterName, parameterValue, atStart/*Add param before others*/){
		replaceDuplicates = true;
		if(url.indexOf('#') > 0){
			var cl = url.indexOf('#');
			urlhash = url.substring(url.indexOf('#'),url.length);
		} else {
			urlhash = '';
			cl = url.length;
		}
		sourceUrl = url.substring(0,cl);

		var urlParts = sourceUrl.split("?");
		var newQueryString = "";

		if (urlParts.length > 1)
		{
			var parameters = urlParts[1].split("&");
			for (var i=0; (i < parameters.length); i++)
			{
				var parameterParts = parameters[i].split("=");
				if (!(replaceDuplicates && parameterParts[0] == parameterName))
				{
					if (newQueryString == "")
						newQueryString = "?";
					else
						newQueryString += "&";
					newQueryString += parameterParts[0] + "=" + (parameterParts[1]?parameterParts[1]:'');
				}
			}
		}
		if (newQueryString == "")
			newQueryString = "?";

		if(atStart){
			newQueryString = '?'+ parameterName + "=" + parameterValue + (newQueryString.length>1?'&'+newQueryString.substring(1):'');
		} else {
			if (newQueryString !== "" && newQueryString != '?')
				newQueryString += "&";
			newQueryString += parameterName + "=" + (parameterValue?parameterValue:'');
		}
		return urlParts[0] + newQueryString + urlhash;
	};

	// home page search above fold event handling
	$('.build-house__go').on('click touchstart', function() {
		// new home / house and land
		var linkTo           = $(this).attr('href'),
			selectedStoreys  = $('.select-storey:checked').val(),
			selectedLotWidth = $('#lot-width').val();
			// selectedLotDepth = $('#lot-depth').val();

		if (selectedStoreys.length > 0) linkTo = addParameter(linkTo, 'storeys', selectedStoreys);
		if (selectedLotWidth.length > 0) linkTo = addParameter(linkTo, 'lot_width', selectedLotWidth);
		// if (selectedLotDepth.length > 0) linkTo = addParameter(linkTo, 'lot_depth', selectedLotDepth);

		window.location = linkTo;
		return false;
	});
	
	if ($('body.estate').length > 0 ) {
		var slidesToShow = 3;
		if ($('.slick-slide').hasClass('col-md-3')) slidesToShow = 3;

		$('.selected_rb_packages.slick-container').slick({
			
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			dots: true,
			autoplay: false,
		
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						dots: false
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 3,
						dots: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				}
			]
		});
		
		$('.selected_hl_packages.slick-container').slick({
			
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			dots: true,
			autoplay: false,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						dots: true
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 3,
						dots: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				}
			]
		});
	
		if($('#gallerySection .slides li').length > 1){
			
			 $('#gallerySection .slides').slick({
			        autoplay: true,           // Enable autoplay
			        autoplaySpeed: 4000,      // Set speed to 4 seconds (4000 ms)
			        speed: 600,               // Transition speed (600 ms)
			        dots: false,               // Show navigation dots
			        arrows: false,             // Show navigation arrows (optional)
			        infinite: true,           // Infinite loop of slides
			        slidesToShow: 1,          // Number of slides to show
			        slidesToScroll: 1,        // Number of slides to scroll at once
			       
			    });
						
		}
	}	

	//testimonails
	if($(".testimonials--slider").length){
		$(".testimonials--slider__slides").slick({

			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true,
			arrows: false,
			//autoplay: true,
			//autoplaySpeed: 6000,
			speed: 600,
		});

		$('.testimonials--slider__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			var $thisSlide = $(slick.$slides.get(currentSlide)),
				iframe = $thisSlide.find('iframe');

			if(iframe.length){
				var vimeoPlayer = new Vimeo.Player(iframe);
				vimeoPlayer.unload();
				iframe.fadeOut()
			}
		})

		$(window).on('load resize', function(){
			eqHeight($('.testimonials--slider__slide .js-eqHeight'))
		});
	}

	$('.testimonials, .testimonial').on('click','.testimonials-play-button', function(){
		var	$this = $(this);

		if($this.hasClass('open-modal')){
			var modal = $('#modal-testimonial-video'),
				iframe = modal.find('iframe');
			modal.modal('show');
			//autoplay video
			iframe.attr('src',$this.data('vimeo') );
			var vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.play();
		}else{
			var	iframe = $this.parent().find('iframe'),
				vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.play();
			iframe.fadeIn()
		}
	})
	$('.book-call-back-watch-video__thumb').on('click', function() {
		var videoSrc = $(this).attr('data-video-src');
		if (typeof videoSrc != 'undefined' && videoSrc !== false) {
			var $modal  = $('#modal-testimonial-video'),
				$iframe = $modal.find('iframe');
			$modal.modal( 'show');
			$iframe.attr('src', videoSrc);
			var vimeoPlayer = new Vimeo.Player($iframe);
			vimeoPlayer.play();
		}
		return false;
	});
	$('.book-call-back-google-reviews .slick-container').slick({
		dots: true,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		swipeToSlide: true
	});
	$('#modal-testimonial-video').on('hidden.bs.modal', function () {
	  	var iframe = $(this).find('iframe');

		if(iframe.length){
			var vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.unload();
		}
	})

	if($('.testimonials--grid-item').length){
		var sm_width = 767,
			md_width = 992;
		function testimonialsEqHeight(){
			var window_width = $(window).width(),
				dataRowSelector = "";

			if(window_width > md_width){
				dataRowSelector = "md"
			}else if(window_width > sm_width){
				dataRowSelector = "sm"
			}

			if(dataRowSelector == ""){
				//mobile
				$('.testimonials--grid-item').css('height','auto');
			}else{
				var rowsArray = [];
				$('.testimonials--grid-item').each(function(){
					//add element to row;
					var rowNumber = $(this).attr('data-' + dataRowSelector + '-row');
					if($.inArray( rowNumber, rowsArray ) == -1){
						rowsArray.push(rowNumber);
					}
				})
				$.each(rowsArray, function(){
					eqHeight($('.testimonials--grid-item[data-' + dataRowSelector + '-row=' + this + ']'))
				})
			}
		}
		testimonialsEqHeight()
		$(window).on('load resize', function(){
			testimonialsEqHeight()
		});

		$('.testimonials--grid-item-see-more').click(function(e){
			if(!mobileView()){
				e.preventDefault();
				var $this = $(this),
					window_width = $(window).width(),
					dataRowSelector = "",
					$readMoreClone = $('.testimonials--grid-more.clone').clone();

				//remove old one
				$('.testimonials--grid-more:not(.clone)').slideUp(300,function(){
					$(this).remove();
				});

				//get details for read more div
				var $seeMoreDataDiv = $this.find('.js-see-more-data');

				$readMoreClone.find('.name').text($seeMoreDataDiv.data('name'));
				//$readMoreClone.find('.short_quote').text($seeMoreDataDiv.data('short_quote'));
				$readMoreClone.find('.description').html($seeMoreDataDiv.data('description'));
				$readMoreClone.find('.series').text($seeMoreDataDiv.data('series'));
				$readMoreClone.find('.image').css('background-image','url(' + $seeMoreDataDiv.data('image') + ')');

				if($seeMoreDataDiv.data('iframe') != ""){
					$readMoreClone.find('.iframe-container iframe').attr('src', $seeMoreDataDiv.data('iframe'));
				}else{
					$readMoreClone.find('.iframe-container').remove();
					$readMoreClone.find('.testimonials--grid-more-col_image').removeClass('col-md-9').addClass('col-md-4');
					$readMoreClone.find('.testimonials--grid-more-col_content').removeClass('col-md-3').addClass('col-md-8');
				}

				//setup read more div
				$readMoreClone.removeClass('clone');

				if(window_width > md_width){
					dataRowSelector = "md"
				}else if(window_width > sm_width){
					dataRowSelector = "sm"
				}

				if(dataRowSelector != ""){
					var rowNumber = $this.attr('data-' + dataRowSelector + '-row'),
						lastElOfRow = $('.testimonials--grid-item[data-' + dataRowSelector + '-row=' + rowNumber + ']').last();

					$readMoreClone.insertAfter(lastElOfRow)

					if($readMoreClone.find('.iframe-container iframe').length){
						$readMoreClone.find('.iframe-container iframe').show();
						vimeoPlayer = new Vimeo.Player($readMoreClone.find('.iframe-container iframe'));
						vimeoPlayer.play();
					}

					$readMoreClone.slideDown(300, function(){
						eqHeight($readMoreClone.find(' > div'))
					});

					$("html, body").animate({ scrollTop: $readMoreClone.offset().top },800);
				}
				return false;
			}
		});

		$('.testimonials').on('click','.testimonials--grid-more .close-more', function(){
			var parent = $(this).parents('.testimonials--grid-more');
			parent.slideUp(300,function(){
				$(this).remove();
			});
		});
	}

	// slick carousel
	$('.carousel-container').on('init', function() {
		var $thisSlider = $(this);
		if ($thisSlider.find('div.slick-track div.slick-active div.vimeoEmbed').length > 0) {
			var $thisSlide = $thisSlider.find('div.slick-track div.slick-active div.vimeoEmbed');
			var vimeoIframeID = $thisSlide.attr('data-vimeo-id');
			if (typeof vimeoIframeID != 'undefined') {
				vimeoDivID = $thisSlide.attr('id');
				var vimeoPlayer = new Vimeo.Player(vimeoDivID);
				vimeoPlayer.on('play', function() {
					$('.carousel-container').slick('slickPause');
				});
				vimeoPlayer.on('pause', function() {
					$('.carousel-container').slick('slickPlay');
				});
				vimeoPlayer.on('ended', function() {
					$('.carousel-container').slick('slickPlay');
				});
			}
		}
	});
	$(".carousel-container").slick({
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		focusOnSelect:true,
		swipeToSlide: true
	});

	$('a.gotoSlide').on('click', function() {
		var slider = '#' + $(this).attr('data-slider'),
		gotoSlide = $(this).attr('data-slide');
		$('.special-offers-aside__links a.current').removeClass('current');
		$(slider).slick('slickGoTo', gotoSlide);
		$(this).addClass('current');

		$('.special-offers-aside__cta a.current').removeClass('current');
		$('.special-offers-aside__cta a[data-slide=' + gotoSlide + ']').addClass('current');

		return false;
	});
	$('.carousel-container').on('afterChange', function(event, slick, currentSlide, nextSlide) {
		var $thisSlide = $(slick.$slides.get(currentSlide));
		var slideNumber = $thisSlide.attr('data-slide');
		var parentRel = $thisSlide.parents('.carousel-container').attr('rel');
		if (typeof parentRel != 'undefined') {
			$('ul.' + parentRel).find('li a.current').removeClass('current');
			$('ul.' + parentRel).find('li a[data-slide=' + slideNumber + ']').addClass('current');
			if (parentRel == 'special-offers-aside__links') {
				var linksRel = parentRel.replace('__links', '__cta');
				$('div.' + linksRel).find('a.current').removeClass('current');
				$('div.' + linksRel).find('a[data-slide=' + slideNumber + ']').addClass('current');

				var mobileLinksRel = parentRel.replace('__links', '__mobile-cta');
				$('div.' + mobileLinksRel).find('a.current').removeClass('current');
				$('div.' + mobileLinksRel).find('a[data-slide=' + slideNumber + ']').addClass('current');
			}
		}
	});
	$('.carousel-container').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		var $thisSlide = $(slick.$slides.get(currentSlide));
		var slideNumber = $thisSlide.attr('data-slide');
		var parentRel = $thisSlide.parents('.carousel-container').attr('rel');
		if (typeof parentRel != 'undefined') {
			if (parentRel == 'special-offers-aside__links') {
				var $vimeoIframe = $($thisSlide.children('div.vimeoEmbed'));
				if (typeof $vimeoIframe != 'undefined') {
					if ($vimeoIframe.length > 0) {
						var vimeoPlayer = new Vimeo.Player($vimeoIframe.attr('id'));
						vimeoPlayer.pause();
					}
				}
			}
		}

		var $nextSlide = $(slick.$slides.get(nextSlide));
		var nextSlideNumber = $nextSlide.attr('data-slide');
		var nextParentRel = $nextSlide.parents('.carousel-container').attr('rel');
		if (typeof nextParentRel != 'undefined') {
			if (nextParentRel == 'special-offers-aside__links') {
				var $nextVimeoIframe = $($nextSlide.children('div.vimeoEmbed'));
				if (typeof $nextVimeoIframe != 'undefined') {
					if ($nextVimeoIframe.length > 0) {
						var nextVimeoPlayer = new Vimeo.Player($nextVimeoIframe.attr('id'));
						nextVimeoPlayer.pause();
						nextVimeoPlayer.on('play', function() {
							$('.carousel-container').slick('slickPause');
						});
						nextVimeoPlayer.on('pause', function() {
							$('.carousel-container').slick('slickPlay');
						});
						nextVimeoPlayer.on('ended', function() {
							$('.carousel-container').slick('slickPlay');
						});
					}
				}
			}
		}
	});

	$('.arrow-mobile').on('click touchstart', 'a', function() {
		var slider = $(this).attr('data-slider');
		if (typeof slider != 'undefined') {
			var $slider = $('#' + slider);
			if ($slider.length > 0) {
				if ($(this).hasClass('arrow-mobile__left')) {
					$slider.slick('slickPrev');
				}
				else if ($(this).hasClass('arrow-mobile__right')) {
					$slider.slick('slickNext');
				}
			}
		}
		return false;
	});

	$('.equal-height').matchHeight({property:"min-height"});

	if( $('.custom-select').length )
		$.jStyling.createSelect($('.custom-select'));

	$('.tab-menu').find('a').on('click', function(e){
		e.preventDefault();
		$('.tab-menu').find('a').removeClass('active')
		$(this).addClass('active');
		var $link = $(this).attr('href');
		$(".tab").each(function() {

			var $tabLink = $(this).attr('id');
			if ($link == $tabLink) {
				$(".tab").hide().removeClass('tab--open');
				$(this).fadeIn(300).addClass('tab--open');
			}
		});
		if ($(this).parents('.hero-popup__inside').length > 0)  {
			// this is from the homepage, push some more data to GTM
			var selectedTabName = $(this).html();
			dataLayer.push({
				event: 'heroPopup',
				heroMenu: selectedTabName
			});
		}
	});

	$('.seo-section .seo-section__read-more').on('click', function() {
		$(this).hide();
		$('.seo-section .seo-section__read-less').show();
		$('.seo-section__text-expandable').css('display', 'inline');
		return false;
	});
	$('.seo-section .seo-section__read-less').on('click', function() {
		$(this).hide();
		$('.seo-section .seo-section__read-more').show();
		$('.seo-section__text-expandable').css('display', 'none');
		return false;
	});

	$('#seo-textarea-section .seo-textarea-section__read-more').on('click', function() {
		$(this).hide();
		$('#seo-textarea-section .seo-textarea-section__read-less').show();
		$('.seo-textarea-section__text-expandable').css('display', 'inline');
		return false;
	});
	$('#seo-textarea-section .seo-textarea-section__read-less').on('click', function() {
		$(this).hide();
		$('#seo-textarea-section .seo-textarea-section__read-more').show();
		$('.seo-textarea-section__text-expandable').css('display', 'none');
		return false;
	});

	/* search */
	$('.search-form').on('submit', function() {
		var searchTerm = $(this).children('input[name=searchTerm]').val();
		if (searchTerm.length > 0) {
			document.location = '/search/?query=' + searchTerm;
		}
		return false;
	});

	function searchUpdatePagination(selectedTab) {
		var $searchStats = $('#searchStats'),
			$newsStats   = $('#newsStats'),
			$pagination  = $('ul.pagination-widget');

		if (selectedTab == '#tab-allpages') {
			var currentPage  = $searchStats.attr('data-current-page'),
				totalPages   = $searchStats.attr('data-total-pages'),
				totalResults = $searchStats.attr('data-total-results'),
				linkClass    = 'search-pagination';
			$('#allPagesCount').html(totalResults);
		}
		else if (selectedTab == '#tab-news') {
			var currentPage  = $newsStats.attr('data-current-page'),
				totalPages   = $newsStats.attr('data-total-pages'),
				totalResults = $newsStats.attr('data-total-results'),
				linkClass    = 'news-pagination';
		}
		currentPage  = parseInt(currentPage);
		totalPages   = parseInt(totalPages);
		$pagination.html('');

		if (totalPages > 0) {
			for (i = 1; i <= totalPages; i++) {
				var liClass = '';
				if (i == currentPage) liClass = ' class="on"';
				$pagination.append('<li' + liClass + '><a href="#" class="' + linkClass + '" data-page-num="' + i + '">' + i + '</a></li>');
				if (i < totalPages) {
					$pagination.append('<li><span>&bull;</span></li>');
				}
			}
		}
	}

	if ($('#searchStats').length > 0) {
		searchUpdatePagination('#tab-allpages');
	}

	$('ul.pagination-widget').on('click', 'a.search-pagination', function() {
		// perform the search but with the next page of search results
		var page = $(this).attr('data-page-num'),
			searchTerm = $('#searchTerm').val();
		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: "/ajax/search.php?query=" + encodeURIComponent(searchTerm),
			data: ({ page: page }),
			dataType: 'html',
			success: function(html) {
				$('.search-results #tab-allpages').html(html);
				$('.tabs-selector a[href=#tab-allpages]').trigger('click');
				searchUpdatePagination('#tab-allpages');
				fadeLoader('out');
			}
		});
		return false;
	});

	$('ul.pagination-widget').on('click', 'a.news-pagination', function() {
		// perform the news search but with the next page of search results
		var page = $(this).attr('data-page-num'),
			searchTerm = $('#searchTerm').val();
		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: "/pages/components/search_news.php?=" + encodeURIComponent(searchTerm),
			data: ({ page: page }),
			dataType: 'html',
			success: function(html) {
				$('.search-results #tab-news').html(html);
				$('.tabs-selector a[href=#tab-news]').trigger('click');
				searchUpdatePagination('#tab-news');
				fadeLoader('out');
			}
		});
		return false;
	});

	$('.tabs-selector').on('click', 'a', function()  {
		var selectedTab = $(this).attr('href');
		$('.tabs-selector li.on').removeClass('on');
		$('.search-results .tab.current').removeClass('current');
		$(selectedTab).addClass('current');
		$(this).parent('li').addClass('on');

		if (selectedTab == '#tab-allpages' || selectedTab == '#tab-news') {
			// update the pagination numbering
			searchUpdatePagination(selectedTab);
			$('.pagination-widget').show();
		}
		else $('.pagination-widget').hide();

		setTimeout(function() {
			$(window).lazyLoadXT();
		}, 50);

		return false;
	});

	if ($('#homeStats').length > 0) {
		var $homeStats = $('#homeStats'),
			searchTotalHomes = $homeStats.attr('data-total-results');
		$('#homesCount').html(searchTotalHomes);
	}
	if ($('#newsStats').length > 0) {
		var $newsStats = $('#newsStats'),
			searchTotalNews = $newsStats.attr('data-total-results');
		$('#newsPostsCount').html(searchTotalNews);
	}

	if ($('#news-rollLayout').length > 0) {
		$('.section-news__list').waypoint(function(direction) {
			var $newsRollSidebar = $('.section-news__aside--secondary');
			if (direction == 'down') {
				$newsRollSidebar.addClass('sticky');
			}
			else {
				$newsRollSidebar.removeClass('sticky');
			}
		}, {
			offset: 25
		});
		$('.section-news__list').waypoint(function(direction) {
			var $newsRollSidebar = $('.section-news__aside--secondary');
			if (direction == 'down') {
				$newsRollSidebar.addClass('sticky-bottom');
			}
			else {
				$newsRollSidebar.removeClass('sticky-bottom');
			}
		}, {
			offset: function() {
				var $newsRoll = $('.section-news__list'),
					$newsRollSlider = $('.section-news__aside--secondary');
					offset = -1 * ($newsRoll.outerHeight() - $newsRollSlider.outerHeight() - 25);
				return offset;
			}
		});
	}

	if ($('body').hasClass('search')) {
		$searchSidebar = $('.search-sidebar');
		$(window).on('scroll', function() {
			var $sidebar           = $searchSidebar.children('sidebar'),
				sidebarOuterHeight = $sidebar.outerHeight(),
				sidebarBottom      = Math.round($searchSidebar.offset().top) + sidebarOuterHeight,
				windowScroll       = document.body.scrollTop,
				scrollHandler      = true,
				$mainPart          = $('.main-part'),
				$mainPartLeft      = $('section.main-part__left');

			if ($mainPart.length > 0) {
				var mainPartHeight = $mainPart.outerHeight();
				if (sidebarOuterHeight >= mainPartHeight) {
					scrollHandler = false;
					if ($sidebar.css('position') != 'relative') $sidebar.css('position', 'relative');
				}
			}

			if (scrollHandler == true) {
				if ($sidebar.css('position') == 'relative') $sidebar.css('position', '');
				if ($('.search-results').length > 0) {
					var searchResultsBottom = Math.round($mainPartLeft[0].getBoundingClientRect().top) + Math.round($mainPartLeft[0].getBoundingClientRect().height),
						searchSidebarBottom = Math.round($sidebar[0].getBoundingClientRect().top) + Math.round($sidebar[0].getBoundingClientRect().height);

					if (searchSidebarBottom >= searchResultsBottom) {
						if (Math.round($sidebar[0].getBoundingClientRect().top) > Math.round($searchSidebar.offset().top)) $sidebar.removeClass('sticky-bottom');
						else $sidebar.addClass('sticky-bottom');
					}
					else {
						$sidebar.removeClass('sticky-bottom');
					}
				}
				else {
					$sidebar.css({ position: 'relative' });
				}
			}
		});
	}

	if ($('body').attr('id') == 'news-postLayout') {
		var $newsPostSidebar = $('.news-post-sidebar'),
			$newsPostColumn = $('.news-post'),
			$newsPost = $('.article--news__body');

		$(window).on('scroll', function() {
			var sidebarBottom = $newsPostSidebar.position().top + $newsPostSidebar.children('aside').outerHeight() + 55,
				articleNewsTop = $newsPostColumn.offset().top,
				articleNewsBottom = $newsPost.offset().top + $newsPost.outerHeight(),
				windowScroll = document.body.scrollTop;
			if (windowScroll > articleNewsTop) {
				$newsPostSidebar.children('aside').addClass('sticky');
			}
			else {
				$newsPostSidebar.children('aside').removeClass('sticky');
			}

			if ((windowScroll + sidebarBottom - 1) > articleNewsBottom) {
				$newsPostSidebar.children('aside').addClass('sticky-bottom');
			}
			else {
				$newsPostSidebar.children('aside').removeClass('sticky-bottom');
			}
		});
	}

	if ($('body').attr('id') == 'productLayout') {
		var $viewPackages = $('.section-home__aside .widget-enquiry'),
			$stickyBar = $('.section-home__sticky-bar'),
			$containerSecondary = $('.section-design .container-secondary'),
			$introBg = $('.intro-bg');
		if ($('.sticky-container-bottom').length > 0) {
			$containerSecondary = $('.sticky-container-bottom');
		}

		$(window).on('scroll', function() {
			if ($viewPackages.length > 0) {
				var viewPackagesBottom = Math.floor($viewPackages.offset().top + $viewPackages.outerHeight()),
					containerSecondaryBottom = Math.floor($containerSecondary.offset().top + $containerSecondary.outerHeight()),
					introBgBottom = $introBg.offset().top + $introBg.outerHeight(),
					windowScroll = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

				if ($(window).width() > 1024) {
					if ((windowScroll - 1) > (introBgBottom - 99)) {
						$viewPackages.addClass('sticky');
					}
					else {
						if (!$viewPackages.hasClass('sticky-bottom')) {
							$viewPackages.removeClass('sticky');
						}
					}

					if ($viewPackages.hasClass('sticky')) {
						// check to see if this needs to stick to the bottom of the container
						// if (windowScroll < (containerSecondaryBottom - 173 - $viewPackages.outerHeight())) {
						if (windowScroll < (containerSecondaryBottom - $viewPackages.outerHeight() - 25)) {
							$viewPackages.removeClass('sticky-bottom');
						}
						else {
							var scrollTrigger = (viewPackagesBottom + 120);
							if (scrollTrigger > containerSecondaryBottom) {
								$viewPackages.addClass('sticky-bottom');
							}
							else if (!$('html').hasClass('ie10')) {
								$viewPackages.removeClass('sticky-bottom');
							}
						}
					}
				}
				else {
					$viewPackages.removeClass('sticky').removeClass('sticky-bottom');
				}
			}
			else if ($stickyBar.length > 0) {
				var currentWindowScroll = $(window).scrollTop(),
					introBottom = $introBg.offset().top + $introBg.height();
				if (currentWindowScroll > introBottom) {
					$stickyBar.addClass('sticky');
				}
				else {
					$stickyBar.removeClass('sticky');
				}
			}
		});
	}else if ($('body').hasClass('general') && $('.widget-enquiry').length) {
		var $widgetGeneral = $('.widget-enquiry'),
			$widgetGeneralContainer = $widgetGeneral.parents(".container__widget"),
			$containerSecondary = $('.section-sticky-limit'),
			$introBg = $('.intro-primary');

		$(window).on('scroll', function() {

			var widgetGeneralBottom = $widgetGeneral.offset().top + $widgetGeneral.outerHeight(),
				containerSecondaryBottom = $containerSecondary.offset().top + $containerSecondary.outerHeight(),
				introBgBottom = $introBg.offset().top + $introBg.outerHeight(),
				windowScroll = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;


			if ($(window).width() > 1024) {
				if ((windowScroll - 1) > (introBgBottom - 99)) {
					$widgetGeneralContainer.addClass('sticky');
				}
				else {
					if (!$widgetGeneralContainer.hasClass('sticky-bottom')) {
						$widgetGeneralContainer.removeClass('sticky');
					}
				}

				if ($widgetGeneralContainer.hasClass('sticky')) {
					// check to see if this needs to stick to the bottom of the container
					// if (windowScroll < (containerSecondaryBottom - 173 - $widgetGeneral.outerHeight())) {
					if (windowScroll < (containerSecondaryBottom - $widgetGeneral.outerHeight() - 25)) {
						$widgetGeneralContainer.removeClass('sticky-bottom');
					}
					else {
						var scrollTrigger = (widgetGeneralBottom + 80);
						if (scrollTrigger > containerSecondaryBottom) {
							$widgetGeneralContainer.addClass('sticky-bottom');
						}
						else if (!$('html').hasClass('ie10')) {
							$widgetGeneralContainer.removeClass('sticky-bottom');
						}
					}
				}
			}
			else {
				$widgetGeneralContainer.removeClass('sticky').removeClass('sticky-bottom');
			}
		});
	}

	$('.main-part').on('click','a.houses-filter__footer-mobile', function(e) {
		e.preventDefault();
		if($('div.houses-filter__sidebar-mobile').hasClass("open")){
			$('div.houses-filter__footer-sticky').removeClass('open');

			$('div.houses-filter__sidebar-mobile').fadeOut(function() {
				$(this).removeClass('open').css('display', '');

				$("body").removeClass('no-scroll');
			});
		}else{
			$('div.houses-filter__footer-sticky').addClass('open');
			$('div.houses-filter__sidebar-mobile').fadeIn(function() {
				$(this).addClass('open').css('display', '');

				$("body").addClass('no-scroll');
			});
		}

		return false;
	});
	$('.houses-filter').on('click', 'a.close', function() {
		$('div.houses-filter__footer-sticky').removeClass('open');

		$('div.houses-filter__sidebar-mobile').fadeOut(function() {
			$(this).removeClass('open').css('display', '');

			$("body").removeClass('no-scroll');
		});
		return false;
	});

	function windowResize() {
		var $housesFilter = $('.houses-filter'),
			$housesFilterSidePanel = $housesFilter.find('.houses-filter__sidepanel'),
			windowWidth = $(window).width();
		if ($housesFilter.length > 0) {
			if (windowWidth < 1200) {
				$housesFilterSidePanel.removeClass('sticky-bottom').removeClass('sticky').children('.houses-filter__sidebar').css({
					top:    '',
					bottom: '',
					width:  'auto'
				});
			}
			Waypoint.refreshAll();
		}
		navigationHeight();
	}

	$(window).on('resize', function() {
		windowResize();
	});

	// feed <-> map switching in product range pages
	$('.houses-filter__panel-switch').on('click', 'a', function() {
		if (!$(this).hasClass('active') && !$(this).parents('.houses-filter__panel-switch').hasClass('ajaxing')) {
			$(this).addClass('active');
			var rel = $(this).attr('data-rel');
			$('.houses-filter__top-panel a.active').removeClass('active');
			$('.houses-filter__top-panel .houses-filter__' + rel + '-switch').addClass('active');
			$('.houses-filter__controls').children(':not(.hidden)').addClass('hidden');
			$('.houses-filter__' + rel + '-controls').removeClass('hidden');
			$('.tabs-tertiary__tab.tabs-tertiary__tab-active').removeClass('tabs-tertiary__tab-active');
			$('#product-range-tab__' + rel).addClass('tabs-tertiary__tab-active');
			Waypoint.refreshAll();

			if($(".filters").hasClass("map_not_initialised")){
				if ($('.houses-filter__map-switch').hasClass('active')) {
					$(this).parents('.houses-filter__panel-switch').addClass('ajaxing');
					// $('.tabs-tertiary__tab-active').fadeTo( 500, 0.2 );
					$(".filters").removeClass("map_not_initialised");
					startMaps();
				}
			}else{
				if($(".filters").hasClass("updated") ){
					$(".filters").removeClass("updated");
					// $('.tabs-tertiary__tab-active').fadeTo( 500, 0.2 );
					$($('.filters input')[0]).trigger('change');
				}
			}
		}
		return false;
	});

	// Accordion
	$('.accordion__head a:not(.accordion-link), .accordion-secondary__head').on('click', function(e){
		e.preventDefault();

		var $this = $(this);

		$this
			.closest('[class*="__section"]')
			.toggleClass('accordion__section-expanded')
			.find(".accordion-secondary__body").slideToggle();
	});

	$(window).on('load', function(){
		// Start iamges slider
		startImagesSlider();

		// Start maps
		if ($('[class*="map"]').length && !$('body').hasClass('house-and-land-landing-page')) {
			startMaps();
		}

		if ($(window).width() >= 375) {
			if (
				$('body').hasClass('new-homes_series') ||
				$('body').hasClass('house-and-land') ||
				$('body').hasClass('inclusions') ||
				$('body').hasClass('general')
			) {
				$('#inclusionsChooserSection article ul li.active').trigger('click');
			}
		}

		if ($('body').hasClass('new-homes_series') || $('body').hasClass('readybuilt_lot')) {
			if ($('#gallerySection').length == 0) {
				$('.icon-anchor-link[data-link="#gallerySection"]').parent().hide();
			}
			var hash = location.hash;
			if (typeof hash != 'undefined') {
				if (hash == '#360view') {
					$('a.icon-anchor-link-360').trigger('click');
				}
			}
		}
	});

	// Tabs functionality
	$('.tabs__nav a').on('click', function(e){
		e.preventDefault();

		var $this = $(this);
		var $target = $($this.attr('href'));

		$this
			.parent()
				.addClass('tabs__nav-active')
				.siblings()
					.removeClass('tabs__nav-active');

		$target
			.addClass('tabs__tab-active')
			.siblings()
				.removeClass('tabs__tab-active');
	});

	$('.houses-filter__sidebar h3').on('click', function(){
		$('.houses-filter__sidebar').toggleClass('houses-filter__sidebar-expanded');
	});

	// Start images slider
	function startImagesSlider() {

		$('.slider-images').not('.estate-gallery').each(function(){
			var $sliderImages = $(this);
			var $sliderImagesContainer = $sliderImages.find('.slides');
			var $sliderCurrent = $sliderImages.find('.slide-images__number');
			var $sliderTotal = $sliderImages.find('.slider-images__total');
			var $totalSlides = $sliderImagesContainer.children().length;
			var $totalTextSlides = $sliderImagesContainer.children().not('.go-to-other-gallery').length;
			$sliderTotal.text($totalTextSlides);

			$sliderImagesContainer.slick();

			$sliderImagesContainer.on('afterChange', function(event, slick, currentSlide){
			  	$sliderCurrent.text(currentSlide + 1);
			  	$(window).scroll();

			  	// get the background image for this slide, if possible
			  	var $currentSlide = $sliderImages.find('.slick-current');
			  	var bgURL = $currentSlide.children('div').css('background-image');
			  	bgURL = /^url\((['"]?)(.*)\1\)$/.exec(bgURL);
			  	bgURL = bgURL ? bgURL[2] : "";

			  	if (bgURL.length > 0) {
			  		bgURL = bgURL.substring(bgURL.lastIndexOf('/') + 1);
			  	}

			  	dataLayer.push({
			  		formType: 'productImage',
			  		formId: $('section-home__head h1').html(),
			  		event: 'productImageView',
			  		imageNumber: currentSlide,
			  		imageName: bgURL
			  	});

				if ($('body').hasClass('new-homes_series')) {
					// change the facade button text, if available
					var facadeName  = $currentSlide.attr('data-facade-name'),
						facadePrice = parseInt($currentSlide.attr('data-facade-price'));
					$('#selectedFacade').html(facadeName);

					// if (facadePrice == 0) $('#selectedFacadePricing').html('');
					// else $('#selectedFacadePricing').html(' ' + priceFormatter.format(facadePrice));
				}
			});

			//new homes detail
			if(
				$("body").hasClass('new-homes_series') ||
				$("body").hasClass('house-and-land') ||
				$("body").hasClass('display-for-sale_detail') ||
				$("body").hasClass('readybuilt_lot') ||
				$("body").hasClass('estate')
			) {
				var end_of_slides = false,
					new_slider;

				$sliderImagesContainer.on('beforeChange', function(event, slick, currentSlide, nextSlide){
					var slider_sections = $("#gallerySection .section__body");

				  	if((currentSlide + 1 == $totalSlides && nextSlide == 0) || ($('#gallerySection .section__body.active .slick-slide[data-slick-index="'+nextSlide+'"]').hasClass("go-to-other-gallery") && currentSlide == 1)){
					  	new_slider = $('#gallerySection .section__body.active').next('.section__body');
					  	if($( "#gallerySection .section__body" ).index( new_slider ) == -1){
						  new_slider = $(slider_sections[0]);
					  	}
					  	new_slider.find(".slides").slick("slickGoTo", 0);
					  	$("#gallerySection .nav-secondary a[data-class='"+new_slider.data("class")+"']").click();
					  	//$(window).scroll();

				  	}else if((currentSlide == 0 && nextSlide + 1 == $totalSlides) || ($('#gallerySection .section__body.active .slick-slide[data-slick-index="'+nextSlide+'"]').hasClass("go-to-other-gallery") && currentSlide == 0)){

					  	new_slider = $('#gallerySection .section__body.active').prev('.section__body');
					  	if($( "#gallerySection .section__body" ).index( new_slider ) == -1){
						  	new_slider = $(slider_sections[slider_sections.length - 1]);
					  	}
					  	var last_slide = new_slider.find(".slides .slick-slide:not(.slick-cloned)").not('.go-to-other-gallery').length - 1;
					  	new_slider.find(".slides").slick("slickGoTo", last_slide);
					  	$("#gallerySection .nav-secondary a[data-class='"+new_slider.data("class")+"']").click();
					  	//$(window).scroll();
				  	}

				  	if (slick.$slider.parents('#facadesSelection').length > 0) {
						setTimeout(function() {
							$('#facadesSelection').lazyLoadXT({
								visibleOnly: false
							});
						}, 50);
				  	}
				});
			}

			if ($('body').hasClass('new-homes_series')) {
				if ($sliderImagesContainer.parents('#facadesSelection').length > 0) {
					var $facadesSelectionSlider = $sliderImagesContainer;
					$facadesSelectionSlider.slick('setOption', 'variableWidth', true, true);
					$facadesSelectionSlider.slick('setOption', 'centerMode', true, true);
					$facadesSelectionSlider.slick('setOption', 'slidesToShow', 1, true);
					$facadesSelectionSlider.slick('setOption', 'slidesToScroll', 1, true);
				}
			}

			//estate
			
			if($("body").hasClass('estate') || $("body").hasClass('display-centre-single')){
				if($('#gallerySection .slides .slide').length == 1){
					$('#gallerySection .slider-images__counter').hide();
				}
			}
		});
	}

	// Load AJAX data
	function loadAjax(filePath) {
		return $.ajax({
			'async': false,
			'global': false,
			'url': filePath
		}).responseJSON;
	}

	$('body').on('click', 'a.scrollTo, button.scrollTo', function() {
		var $thisObj = $(this),
			scrollTo = $thisObj.attr('data-scrollto'),
			offset;
		if (typeof scrollTo != 'undefined') {
			if ($('#' + scrollTo).length > 0) {
				offset = 30;
				if ($('.section-home__sticky-bar').length > 0) {
					offset = $('.section-home__sticky-bar').height();
				}
				var scrollPos = $('#' + scrollTo).offset().top - offset;
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
		return false;
	});
	$('body').on('click', 'a.scrollToBottom', function() {
		var scrollToElement = $(this).attr('data-scrollto'),
			offset;
		if (typeof scrollToElement != 'undefined') {
			if ($(scrollToElement).length > 0) {
				offset = 0;
				var scrollPos = $(scrollToElement).position().top + $(scrollToElement).outerHeight() - offset;
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
		return false;
	});

	$('.section-home__read-more').on('click', function() {
		$(this).hide();
		$('.section-home__expandable').css({
			display: 'inline'
		});
		return false;
	});
	$('.section-home__read-less').on('click', function() {
		$(this).parent().hide();
		$('.section-home__read-more').css({
			display: 'inline'
		});
		return false;
	});

	function createCookie(name, value, days) {
	    var date, expires;
	    if (days) {
	        date = new Date();
	        date.setTime(date.getTime()+(days*24*60*60*1000));
	        expires = "; expires="+date.toGMTString();
	    } else {
	        expires = "";
	    }
	    document.cookie = name+"="+value+expires+"; path=/";
	}

	function getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	function eraseCookie(name) {
		document.cookie = name + '=; Max-Age=-99999999; ';
	}

	$('.widget-enquiry__favourite').on('click', function() {
		$(this).toggleClass('selected');

		var page_id = $(this).data('page-id'),
		    status = "no";

		if($(this).hasClass('selected')){
			createCookie("favourite_page_"+page_id, "yes", 1);
		}else{
			createCookie("favourite_page_"+page_id, "no", -1);
		}
		return false;
	});

	if($('body').hasClass('house-and-land') || $('body').hasClass('readybuilt') || $("body").hasClass('display-for-sale_detail')) {
		$(window).load(function() {
			var svgContainer = document.getElementById('svg1');

			if (typeof svgContainer != 'undefined' && svgContainer != null ) {
				var svgDoc = svgContainer.contentDocument;
				if (svgDoc != null) {
					if (typeof svgDoc == 'object') {
						if (!$('body').hasClass('readybuilt')) {
							$(svgDoc).find("g[id]").css('display','none');
						}
						var svg_object = $("#"+svgContainer.id);
						if (svg_object.attr('data-layer-id')) {
							var layerID = svg_object.attr('data-layer-id').replace(/_/g, '_x5F_');
							$(svgDoc).find("#"+layerID).css('display','block');
						}
						ini_svgPanZoom('#svg1');
						$('.design__image').css('visibility', 'visible');
					}
				}
			}
		});
	}

	// floor plan size switching
	if($('.design__select-design').length > 0) {
		$(window).load(function() {
			var $floorplans = $('.design__image').children('.floorplan');
			$floorplans.each(function() {
				var svg_id = $(this).children('object').attr('id');
				$('.design__select a.disabled[data-page-id="' + svg_id.replace('svg', '') + '"]').removeClass('disabled');
				$('.zoom-in-modal .control__container select option[data-page-id=' + svg_id.replace('svg', '') + ']').removeClass('disabled');
				$('.design__image').css('visibility', 'visible');
			});
		});
	}

	$('.design__select-design').on('click', '.design__select a', function() {
		var page_id = $(this).data('page-id');

		if (!$(this).hasClass('disabled') && !$(this).hasClass('active')) {
			//SVG
			if (!$('html').hasClass('firefox') && !($('html').hasClass('ie')) && !($('html').hasClass('edge'))) {
				$('.design__image').css('visibility', 'hidden');
			}
			$('.design__image').children('.floorplan.active').removeClass('active');
			$('.design__image').children('#floorplan-' + page_id).addClass('active');

			$('.zoom-in-modal .center .right.block').css('visibility', 'hidden');
			$('.zoom-in-modal .center .right.block .floorplan.active').removeClass('active');
			$('#zoomfloorplan-' + page_id).addClass('active');

			//Change elements with class: change_on_series_home_click
			$('.change_on_series_home_click').removeClass('active');
			$('.change_on_series_home_click[data-page-id='+ page_id +']').addClass('active');

			// change the print icon to point to the right PDF file
			var pdfLink = $('.change_on_series_home_click.active.pdfBrochureLink').children('a').attr('href');
			$('.design__inner-actions a.downloadPDF').attr('href', pdfLink);

			//zoom_in_select
			$(".zoom-in-modal .control__container select option[data-page-id=" + page_id + "]").prop('selected', true);

			//button
			$('.design__select li a.active').removeClass('active');
			$(this).addClass('active');

			// clear any selected choices and addons
			$('.choices_and_add_ons').find('a.togglePlan.selected').removeClass('selected');

			// get facade pricing if available
			if ($('body').hasClass('new-homes_series')) {
				// find the active floorplan panel
				var $activeFloorplan = $('.design__select-design').find('.this-floorplan.active');
				if ($activeFloorplan.length > 0) {
					// update the inclusion pricinng so it's reflective of that floor plan size
					var $floorplanPrices = $activeFloorplan.find('.floorplan-prices'),
						baseWasPrice     = $floorplanPrices.children('.current_price').attr('data-price'),
						baseNowPrice     = $floorplanPrices.children('.new_price').attr('data-price'),
						livingWasPrice   = $floorplanPrices.children('.current_price_living').attr('data-price'),
						livingNewPrice   = $floorplanPrices.children('.new_price_living').attr('data-price'),
						reserveWasPrice  = $floorplanPrices.children('.current_price_reserve').attr('data-price'),
						reserveNewPrice  = $floorplanPrices.children('.new_price_reserve').attr('data-price');

					// set each inclusion button
					$('#inclusionsChooserSection').find('.pricing-trigger').each(function() {
						if ($(this).hasClass('pricing-trigger-current_price')) {
							$(this).attr('data-inclusion-was-price', baseWasPrice);
							$(this).attr('data-inclusion-price', baseNowPrice);
						}
						if ($(this).hasClass('pricing-trigger-current_price_living')) {
							$(this).attr('data-inclusion-was-price', livingWasPrice);
							$(this).attr('data-inclusion-price', livingNewPrice);
						}
						if ($(this).hasClass('pricing-trigger-current_price_reserve')) {
							$(this).attr('data-inclusion-was-price', reserveWasPrice);
							$(this).attr('data-inclusion-price', reserveNewPrice);
						}
					});

					// set the inclusion headers
					$('#inclusionsChooserSection').find('.tier-selector li button').each(function() {
						if ($(this).hasClass('pricing-trigger-new_price')) {
							$(this).parent().find('span.fromPrice').html(priceFormatter.format(baseNowPrice));
						}
						if ($(this).hasClass('pricing-trigger-new_price_living')) {
							$(this).parent().find('span.fromPrice').html(priceFormatter.format(livingNewPrice));
						}
						if ($(this).hasClass('pricing-trigger-new_price_reserve')) {
							$(this).parent().find('span.fromPrice').html(priceFormatter.format(reserveNewPrice));
						}
					});

					// get the currently selected inclusion pricing to update the was and now pricing, patch into previous code
					var $activeInclusionButton = $('#inclusionsSelectTable').find('.pricing-trigger.active'),
						inclusionWasPrice      = $activeInclusionButton.attr('data-inclusion-was-price'),
						inclusionNowPrice      = $activeInclusionButton.attr('data-inclusion-price');
					$activeFloorplan.attr('data-was-price', inclusionWasPrice);
					$activeFloorplan.attr('data-price', inclusionNowPrice);

					var wasPrice           = $activeFloorplan.attr('data-was-price'),
						nowPrice           = $activeFloorplan.attr('data-price'),
						$widgetEnquiryHead = $('.widget-enquiry__head'),
						$seriesHomePrice   = $widgetEnquiryHead.find('.series_home_price'),
						$strikePrice       = $seriesHomePrice.find('span.strike'),
						$designPrice       = $seriesHomePrice.find('.design-price'),

						$step1FromPriceDiv = $activeFloorplan.find('.from-price'),
						$step1FromPrice    = $step1FromPriceDiv.children('h3'),
						$step1StrikePrice  = $step1FromPriceDiv.children('h4.strike');

					$designPrice.attr('data-inclusions-price', nowPrice);
					$designPrice.attr('data-inclusions-was-price', wasPrice);

					var fNowPrice = calculateNowPrice();
					$designPrice.html(priceFormatter.format(fNowPrice));
					$step1FromPrice.html('From ' + priceFormatter.format(fNowPrice));

					if (wasPrice != '' && wasPrice > 0) {
						$strikePrice.attr('data-was-price', wasPrice);

						// get the slash svg
						var $slashSvg = $seriesHomePrice.find('span.strike').children('svg');
						var fWasPrice = calculateWasPrice();

						var slashSVGHTML = '';
						if ($slashSvg.length > 0) {
							slashSVGHTML = $slashSvg[0];
							if ($('html').hasClass('ie11')) slashSVGHTML = new XMLSerializer().serializeToString(slashSVGHTML);
							else slashSVGHTML = $slashSvg[0].outerHTML;
						}

						$seriesHomePrice.find('h3').addClass('has_strike');
						$strikePrice.show().html(priceFormatter.format(fWasPrice) + slashSVGHTML).attr('data-was-price', fWasPrice);

						// update the was price for the currently selected floor plan container in step 1
						$step1StrikePrice.html('From ' + priceFormatter.format(fWasPrice) + slashSVGHTML).show();
					}
					else {
						// hide strike price in sticky bar
						$seriesHomePrice.find('h3').removeClass('has_strike').find('span.strike').hide().attr('data-was-price', '').html('');
						$step1StrikePrice.hide();
					}
					updateFinalSelection();
				}

				var floorplanID       = $(this).attr('data-page-id'),
					floorplanName     = $(this).attr('data-filename'),
					seriesID          = $('body').attr('data-page-id'),
					$facadeSlickTrack = $('#facadesSelection .slick-track'),
					$facadeGallery    = $facadeSlickTrack.children(),
					facades           = [];

				$facadeGallery.each(function() {
					var thisFacade = $(this).attr('data-facade-name');
					facades.push(thisFacade);
				});

				if (facades.length > 0) {
					$.ajax({
						type: 'POST',
						url: '/ajax/get_facade_pricing.php',
						data: ({
							series_id:    seriesID,
							floorplan_id: floorplanID,
							facade_names: facades
						}),
						dataType: 'json',
						success: function(response) {
							if (response.result.length > 0) {
								for (i in response.result) {
									var facadeRow = response.result[i];

									$facadeGallery.each(function() {
										var $thisFacade = $(this),
											facadeImageID = $thisFacade.attr('id');
										if ($thisFacade.attr('data-facade-name') == facadeRow.facade_name) {
											$('#' + facadeImageID).attr('data-facade-price', Math.round(facadeRow.price));
										}
									});
								}
								// set the currently selected slide facade price in button
								var currentSlidePrice = $facadeSlickTrack.find('li.slick-active').attr('data-facade-price'),
									$selectedFacadePricingButton = $('#selectedFacadePricing');
								// $selectedFacadePricingButton.html(' ' + priceFormatter.format(currentSlidePrice));
								updateFinalSelection();
							}
						}
					});
				}

				// update download brochure button
				var $downloadBrocuhreButton = $('#widget-enquiry__download-brochure'),
					$downloadBrocuhreButtonTwo = $('.downloadPDF'),
					$downloadSummaryButton  = $('a.home-design-selection__download-pdf'),
					downloadURL             = $downloadBrocuhreButton.attr('href'),
					newDownloadURL          = downloadURL.replace(/print-pdf\/([0-9]+)\&/, 'print-pdf/' + floorplanID + '&')
												.replace(/&filename=([a-zA-Z0-9_-]+)/, '&filename=' + floorplanName);
				$downloadBrocuhreButton.attr('href', newDownloadURL);
				$downloadSummaryButton.attr('href', newDownloadURL);
				$downloadBrocuhreButtonTwo.attr('href', newDownloadURL);
			}

			if ($('html').hasClass('ie') || /Edge/.test(navigator.userAgent)) {
				svgOnLoad('svg' + page_id);
				if ($('.zoom-in-modal').css('visibility') == 'visible') {
					svgOnLoad('zoom_svg' + page_id);
				}
			}
			if ($('html').hasClass('firefox')) {
				ini_svgPanZoom('#svg' + page_id);
				if ($('.zoom-in-modal').css('visibility') == 'visible') {
					svgOnLoad('zoom_svg' + page_id);
				}
			}
			$('.design__image').css('visibility', 'visible');
		}
		return false;
	});

	$('a.virtual-tour__load').on('click', function() {
		var $this            = $(this),
			$iframeContainer = $this.parents('.virtual-tours').find('.iframe-container'),
			$iframe          = $iframeContainer.children('iframe'),
			iframeSrc        = $iframe.attr('data-load-src');

		$iframe.attr('src', iframeSrc);
		$iframeContainer.removeClass('hidden');
		$this.addClass('fadeHide');
		var setHide = setTimeout(function() {
			$this.addClass('hidden').removeClass('fadeHide');
		}, 600);
		return false;
	});

	var priceFormatter = new Intl.NumberFormat('en', {
		style: 'currency',
		currency: 'usd',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	});

	$('.design-step.design-step__2').on('click', 'a.select-inclusion-tier', function() {
		var selectedTier = $(this).attr('data-tier');
		$('button#inclusion-select-button-' + selectedTier).trigger('click');
		return false;
	});
	$('body.inclusions').on('click', 'a.select-inclusion-tier', function() {
		return false;
	});
	// selecting an inclusion level
	$('.design-step.design-step__2 .design-step__next-step').on('click', 'button', function() {
		var $designPrice       = $('.widget-enquiry__head').find('.design-price'),
			$strikePrice       = $('.series_home_price span.strike'),
			$slashSvg          = $strikePrice.children('svg'),
			$activeFloorplan   = $('.design__select-design').find('.this-floorplan.active'),

			$step1FromPriceDiv = $activeFloorplan.find('.from-price'),
			$step1FromPrice    = $step1FromPriceDiv.children('h3'),
			$step1StrikePrice  = $step1FromPriceDiv.children('h4.strike'),

			inclusionPrice     = $(this).attr('data-inclusion-price'),
			wasInclusionPrice  = $(this).attr('data-inclusion-was-price');

		$designPrice.attr('data-inclusions-price', inclusionPrice);
		$designPrice.attr('data-inclusions-was-price', wasInclusionPrice);

		var fNowPrice = calculateNowPrice();
		$designPrice.html(priceFormatter.format(fNowPrice));
		$step1FromPrice.html('From ' + priceFormatter.format(fNowPrice));

		var fWasPrice = calculateWasPrice();
		var slashSVGHTML = '';
		if ($slashSvg.length > 0) {
			slashSVGHTML = $slashSvg[0];
			if ($('html').hasClass('ie11')) slashSVGHTML = new XMLSerializer().serializeToString(slashSVGHTML);
			else slashSVGHTML = $slashSvg[0].outerHTML;
		}

		$strikePrice.html(priceFormatter.format(fWasPrice) + slashSVGHTML).attr('data-was-price', fWasPrice);
		$step1StrikePrice.html('From ' + priceFormatter.format(fWasPrice) + slashSVGHTML);

		$('.tier-selector button.active').removeClass('active');
		$(this).addClass('active');

		// trigger govt grant checkboxes
		setTimeout(function() {
			$activeFloorplan.find('.eligible-for__panel .eligible-for__check').trigger('change');
		}, 250);
		updateFinalSelection();
	});

	if ($('body').hasClass('house-and-land')) {
		$('.design-step.design-step__2-h-and-l .design-step__next-step').on('click', 'button', function() {
			updateFinalSelection();
		});
	}

	// selecting a facade
	$('.section__select-facade .select-facade').on('click', function() {
		var $designPrice  = $('.widget-enquiry__head').find('.design-price'),
			$strikePrice  = $('.series_home_price span.strike'),
			$slashSvg     = $strikePrice.children('svg'),
			$currentSlide = $('#facadesSelection .slide.slick-current'),
			facadePrice   = parseInt($currentSlide.attr('data-facade-price')),

			$activeFloorplan   = $('.design__select-design').find('.this-floorplan.active'),
			$step1FromPriceDiv = $activeFloorplan.find('.from-price'),
			$step1FromPrice    = $step1FromPriceDiv.children('h3'),
			$step1StrikePrice  = $step1FromPriceDiv.children('h4.strike');

		$designPrice.attr('data-facade-price', facadePrice);
		$('.section__select-facade .select-facade.active').removeClass('active')
		$(this).addClass('active');

		updateFinalSelection();
	});

	function calculateNowPrice() {
		var $designPrice           = $('.widget-enquiry__head').find('.design-price'),
			selectedInclusionPrice = parseInt($designPrice.attr('data-inclusions-price')),
			selectedFacadePrice    = parseInt($designPrice.attr('data-facade-price'));

		// disabling facade pricing
		// returnValue = selectedInclusionPrice + selectedFacadePrice;
		returnValue = selectedInclusionPrice;
		return returnValue;
	}

	function calculateWasPrice() {
		var $designPrice           = $('.widget-enquiry__head').find('.design-price'),
			selectedInclusionPrice = parseInt($designPrice.attr('data-inclusions-was-price')),
			selectedFacadePrice    = parseInt($designPrice.attr('data-facade-price'));

		// disabling facade pricing
		// returnValue = selectedInclusionPrice + selectedFacadePrice;
		returnValue = selectedInclusionPrice;
		return returnValue;
	}

	function updateFinalSelection() {
		var $finalPanel      = $('#homeDesignSelection'),
			$floorplan       = $finalPanel.find('.home-design-selection__floorplan'),
			$inclusions      = $finalPanel.find('.home-design-selection__inclusions'),
			$facade          = $finalPanel.find('.home-design-selection__facade'),
			$headerSticky    = $('.section-home__sticky-bar'),
			$selectedOptions = $headerSticky.find('.price-selected-options'),

			$selectedFloorplan      = $('.this-floorplan.active'),
			$selectInclusionsButton = $('#inclusionsChooserSection').find('.tier-selector button.active'),
			$selectFacadeButton     = $('.section__select-facade .select-facade.active .selectedFacade');

		var selectedFloorplan  = "",
			selectedInclusions = $selectInclusionsButton.attr('data-inclusion-name'),
			selectedFacade     = 'No façade selected';

		if (typeof $selectedFloorplan.attr('data-series-name') != 'undefined') {
			var selectedFloorplan = $selectedFloorplan.attr('data-series-name');
		}
		if (typeof $selectedFloorplan.attr('data-floorplan-size') != 'undefined') {
			if (selectedFloorplan.length == 0) selectedFloorplan = $selectedFloorplan.attr('data-floorplan-size');
			else selectedFloorplan += " " + $selectedFloorplan.attr('data-floorplan-size');
		}

		if ($selectFacadeButton.length > 0) selectedFacade = $selectFacadeButton.html() + " façade";

		$floorplan.children('p.value').html(selectedFloorplan);
		$inclusions.children('p.value').html(selectedInclusions);
		$facade.children('p.value').html(selectedFacade);

		// hidden fields for enquiry form
		var $enquiryForm              = $('#enquiryForm'),
			$hiddenFloorplanSize      = $enquiryForm.find('#floorplan_size'),
			$hiddenSelectedInclusions = $enquiryForm.find('#selected_inclusions'),
			$hiddenSelectedFacade     = $enquiryForm.find('#selected_facade');
		$hiddenFloorplanSize.val(selectedFloorplan);
		$hiddenSelectedInclusions.val(selectedInclusions);
		$hiddenSelectedFacade.val(selectedFacade);

		// clear out the modal heading span
		$('span.facade-inclusions-choice').each(function() {
			$(this).html('');
		});

		var selectedOptions = ""
			selectedOptionsEnquiry = "";

		if (selectedFloorplan.length > 0) {
			if (selectedFloorplan != 'undefined') {
				selectedOptions += selectedFloorplan + ', ';
				selectedOptionsEnquiry += selectedFloorplan + ', ';
			}
		}
		if (selectedInclusions != undefined && selectedInclusions.length > 0) {
			if (selectedInclusions != 'undefined') {
				if (selectedInclusions.indexOf('nclusion') > -1) {
					selectedOptions += selectedInclusions + ', ';
				}
				else {
					selectedOptions += selectedInclusions + ' inclusions, ';
				}
				selectedOptionsEnquiry += selectedInclusions + ', ';
			}
		}
		if (selectedFacade.length > 0 && selectedFacade != 'No façade selected') {
			// selectedOptions += selectedFacade;
			selectedOptionsEnquiry += selectedFacade + ', ';
		}
		if (selectedOptions.length > 0) {
			// trim off trailing comma if there's no selected facade
			if ($selectFacadeButton.length == 0) {
				selectedOptions = selectedOptions.replace(' inclusions, ', ' inclusions');
			}
			selectedOptionsEnquiry = selectedOptionsEnquiry.replace(' façade, ', ' façade').replace(/(^[,\s]+)|([,\s]+$)/g, '');

			// update the modal heading
			$('#enquiryForm').find('.form-enquiry-secondary__aside.active span.facade-inclusions-choice').html(selectedOptionsEnquiry);

			if ($('body').hasClass('house-and-land')){
				selectedOptions = selectedOptions.replace(/,\s$/, '').replace('Plantation ', '').replace('inclusions', 'Inclusions');
				$('.from-price .price-selected-options').html(selectedOptions);
			}
			else {
				selectedOptions = "Selected: " + selectedOptions.replace(/,\s$/, '');
			}
			$selectedOptions.html(selectedOptions).addClass('active');
		}

		$finalPanel.parent().slideDown();
	}

	$('.zoom-in-modal .control__container').on('change', 'select', function() {
		var pageID = $(this).find(':selected').attr('data-page-id');
		$('.design__select-design a[data-page-id=' + pageID + ']').click();
	});

	function flipflopFloorplan(viewPort, flip) {
		$levelLayers = viewPort.children('g');
		if ($levelLayers.attr('id') == 'undefined') {
			$levelLayers = viewPort.children('g').children('g');
		}

		if ($levelLayers.length == 1) {
			$levelLayersNew = $levelLayers.children('g');
			$levelLayers = $levelLayersNew;
			delete $levelLayersNew;
		}
		if ($levelLayers.filter('g[id$="-ground-floor"], g[id$="-first-floor"]').length > 0) {
			// multilevel
			var $groundLevel = $levelLayers.filter('g[id$="-ground-floor"]'),
				$groundBase  = $groundLevel.children('g[id$="-base"]'),
				$groundFlip  = $groundLevel.children('g[id$="-flip"]');

			var $firstLevel = $levelLayers.filter('g[id$="-first-floor"]'),
				$firstBase  = $firstLevel.children('g[id$="-base"]'),
				$firstFlip  = $firstLevel.children('g[id$="-flip"]');

			var $dualLevel;
			if ($levelLayers.filter('g[id$="-dual-floor"]').length > 0) {
				$dualLevel = $levelLayers.filter('g[id$="-dual-floor"]');
			}
		}
		else if ($levelLayers.children('g[id$="-ground-floor"], g[id$="-first-floor"]').length > 0) {
			// multilevel
			var $groundLevel = $levelLayers.children('g[id$="-ground-floor"]'),
				$groundBase  = $groundLevel.children('g[id$="-base"]'),
				$groundFlip  = $groundLevel.children('g[id$="-flip"]');

			var $firstLevel = $levelLayers.children('g[id$="-first-floor"]'),
				$firstBase  = $firstLevel.children('g[id$="-base"]'),
				$firstFlip  = $firstLevel.children('g[id$="-flip"]');

			var $dualLevel;
			if ($levelLayers.filter('g[id$="-dual-floor"]').length > 0) {
				$dualLevel = $levelLayers.filter('g[id$="-dual-floor"]');
			}
		}
		else {
			// single level, does not have *-ground-floor or *-first-floor layers
			var $groundBase  = $levelLayers.filter('g[id$="-base"]'),
				$groundFlip  = $levelLayers.filter('g[id$="-flip"]');
			if ($groundBase.length == 0) {
				$groundBase = $levelLayers.children('g[id$="-base"]');
			}
			if ($groundFlip.length == 0) {
				$groundFlip  = $levelLayers.children('g[id$="-flip"]');
			}
		}

		if ($groundFlip.length > 0) {
			var flipDisplay = 'none',
				baseDisplay = 'block';
			if (flip == 'flip') {
				// restore to base layer
				flipDisplay = 'block';
				baseDisplay = 'none';
			}
			$groundBase.attr('display', baseDisplay);
			$groundFlip.attr('display', flipDisplay);
			if (typeof $firstLevel != 'undefined') {
				$firstBase.attr('display', baseDisplay);
				$firstFlip.attr('display', flipDisplay);
			}
		}
	}

	$('.choices_and_add_ons').on('click', 'a.togglePlan', function() {
		var $toggle       = $(this),
			toggleID      = $toggle.attr('id'),
			$toggleParent = $(this).parent(),
			svgID         = $toggle.attr('data-svg'),
			svgLayerID    = $toggle.attr('data-g-id'),
			flipOption    = $toggle.attr('data-floorplan-flip');

		var svgCont     = document.getElementById(svgID);

		if (typeof svgCont != 'undefined' && svgCont != null) {
			var svgDoc = svgCont.contentDocument;
			if (svgDoc != null) {
				if (typeof svgDoc == 'object') {
					var $viewPort     = $(svgDoc).find('g.svg-pan-zoom_viewport');
					if ($viewPort.length == 0) $viewPort = $(svgDoc).find('g[id^="viewport-"]');

					var $dualLevel;

					if ($viewPort.find('g[id$="-dual-floor"]').length > 0) {
						$dualLevel     = $viewPort.find('g[id$="-dual-floor"]');
					}

					// unselect whichever option is already selected
					if ($('.choices_and_add_ons a.togglePlan.selected[id!="' + toggleID + '"]').length > 0) {
						$('.choices_and_add_ons a.togglePlan.selected[id!="' + toggleID + '"]').trigger('click');
					}

					if (typeof flipOption != 'undefined') {
						if ($toggle.hasClass('selected')) {
							flipflopFloorplan($viewPort, 'base');
							$toggle.removeClass('selected');
							$toggleParent.removeClass('selected');
						}
						else {
							flipflopFloorplan($viewPort, 'flip');
							$toggle.addClass('selected');
							$toggleParent.addClass('selected');
						}
					}
					else {
						var dualLayer  = false,
							$svgLayer  = false,
							$svgLayer2 = false;

						if ($toggle.attr('data-layer')) {
							if ($toggle.attr('data-layer') == 'dual') dualLayer = true;
						}

						if (dualLayer == true) {
							var svgGroundLayerID = $toggle.attr('data-g-ground-id'),
								svgFirstLayerID  = $toggle.attr('data-g-first-id'),
								$svgLayer        = $('#' + svgGroundLayerID, svgDoc),
								$svgLayer2       = $('#' + svgFirstLayerID, svgDoc);
						}
						else {
							var $svgLayer     = $('#' + svgLayerID, svgDoc);
						}

						if ($toggle.hasClass('selected')) {
							// turn off
							$svgLayer.attr('display', 'none');

							$toggle.removeClass('selected');
							$toggleParent.removeClass('selected');
							if (dualLayer) {
								if (typeof $dualLevel != 'undefined') {
									$dualLevel.children().each(function() {
										$(this).attr('display', 'none');
									});
								}
							}
						}
						else {
							// turn on
							$svgLayer.attr('display', 'block');
							if (dualLayer) {
								var floorplanTranslate = $('body').attr('data-floorplan-default');
								floorplanTranslate = parseInt(floorplanTranslate);
								if (floorplanTranslate < 1) floorplanTranslate = 175;

								// check if there's adjustment
								var $svgContainer = $('#' + svgID);
								if (typeof $svgContainer.attr('data-adjustment') != 'undefined') {
									var floorplanAdjustment = $svgContainer.attr('data-adjustment');
									if (floorplanAdjustment.length > 0) {
										floorplanAdjustment = parseInt(floorplanAdjustment);
										floorplanTranslate += floorplanAdjustment;
									}
								}

								if (typeof $dualLevel != 'undefined') {
									$svgLayer.attr('transform', 'translate(-' + floorplanTranslate + ')'); // left side
									$svgLayer2.attr('display', 'block').attr('transform', 'translate(' + floorplanTranslate + ')');
									$dualLevel.attr('display', 'block');
								}
							}
							$toggle.addClass('selected');
							$toggleParent.addClass('selected');
						}
					}

					var svgPZ = svgPanZoom('#' + svgID);

					if ($('html').hasClass('mobile')) {
						if ($('#' + svgID).attr('data-storeys') != '1') {
							svgPZ.updateBBox();
							svgPZ.reset();
							svgPZ.fit();

						}
						else {
							svgPZ.reset();
						}
					}
					else {
						svgPZ.reset();
						if ($('#' + svgID).attr('data-zoom').length > 0) {
							var zoomLevel = $('#' + svgID).attr('data-zoom');
							svgPZ.zoom(zoomLevel);
						}
					}
				}
			}
		}
		return false;
	});

	$('.zoom_choices_and_add_ons').on('click', 'a.togglePlan', function() {
		var thisID = $(this).attr('id');
		$('#' + thisID.replace('zoomPlanOption-', 'planOption-')).trigger('click');
		return false;
	});

	$('.design__inner-actions').on('click', 'a.flipFloorplan', function() {
		var $currentPanel = $('.this-floorplan.active');
		if ($currentPanel.length > 0) {
			var $flipClick = $currentPanel.find('.accordion__body .flipFloorplan').trigger('click');
		}
		$(this).toggleClass('active');
		return false;
	});

	if ($('.design__inner').length > 0) {
		if ($('html').hasClass('safari') || $('html').hasClass('firefox')) {
			if ($('ul.design__select').length > 0) {
				var currentID = $('ul.design__select li a.active').attr('data-page-id');
				svgOnLoad('svg' + currentID);
			}
		}
	}

	// subscribe form in footer
	$('#footerSubscribeForm').validate({
		errorClass: 'error',
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#footerSubscribeForm').serialize(),
				$submitButton = $('#footerSubscribeForm input[type=submit]'),
				submitValue = $submitButton.val();
			serializedForm += "&url=" + window.location;
			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						dataLayer.push({
							formType: 'footerSubscribeForm',
							formId: 'footerSubscribeForm',
							event: 'formSubmissionSuccess'
						});

						$(form)[0].reset();
						$('.subscribe-row').children('p').after('<p>' + response.message + '</p>');
					}
					else {
						alert(response.message);
					}
				}
			});
		},
	});

	$('.section-info__group-form-select').on('click', 'a.button', function() {
		var $formShell   = $('.section-info__group-form-shell'),
			$formSelect  = $('.section-info__group-form-select'),
			selectedForm = $(this).attr('href');
		$formSelect.find('a.button').removeClass('active');
		$(this).addClass('active');
		$formShell.children('.section-info__group-form-container').removeClass('active');
		$formShell.children(selectedForm).addClass('active');
		return false;
	});
	if ($('body').hasClass('display-centre-single')) {
		if ($('#d365o-appointment').length > 0) {
			setTimeout(function () {
				$('#inputLocation').trigger('change');
			}, 3000);
		}
	}

	// booking an appointment from a display centre page
	$('#bookAppointment').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#bookAppointment').serialize(),
				$submitButton = $('#bookAppointment button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/api_contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();

						var $thankyouDiv = $('#bookAppointment').next('.form-booking__thank-you');
						$thankyouDiv.children('h2').html(response.message);
						$thankyouDiv.removeClass('hidden');

						 dataLayer.push({
	                        formType: 'displayEnquiry',
	                        formId: 'bookAppointment',
	                        event: 'formSubmissionSuccess'
	                    });

						dataLayer.push({
							formType: 'bookAppointment',
							formId: 'bookAppointment',
							enquiringAbout: $('#inputEnquiry').val(),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// ready built enquiry on form when there's no ready built homes available
	$('#readyBuiltEnquiry-noResults').validate({
		rules: {
			email: {
					required: true,
					email: true,
				}
		},
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#readyBuiltEnquiry-noResults').serialize(),
				$submitButton = $('#readyBuiltEnquiry-noResults button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			$('#readyBuiltEnquiry-noResults p').remove();

			$.ajax({
				type: 'POST',
				url: '/ajax/api_contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#readyBuiltEnquiry-noResults').hide();
						$('.houseAndLandEnquiry-thankyou').removeClass('hidden');
						
						('<p>' + response.message + '</p>');
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// house and land enquiry on form when there's no ready built homes available
	$('#houseAndLandEnquiry-noResults').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#houseAndLandEnquiry-noResults').serialize(),
				$submitButton = $('#houseAndLandEnquiry-noResults button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			$('#houseAndLandEnquiry-noResults p').remove();

			$.ajax({
				type: 'POST',
				url: '/ajax/api_contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#houseAndLandEnquiry-noResults').hide();
						$('.houseAndLandEnquiry-thankyou').removeClass('hidden');
						
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});


	// sidebar subscribe function
	$('#sidebarSubscribe').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).addClass('error').prev('label').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error').prev('label').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#sidebarSubscribe').serialize(),
				$submitButton = $('#sidebarSubscribe button[type=submit'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			serializedForm += "&url=" + window.location;
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						if ($('#sidebarSubscribe p').length == 0) {
							$('#sidebarSubscribe').prepend('<p>' + response.message + '</p>');
						}
						else {
							$('#sidebarSubscribe p').html(response.message);
						}
						dataLayer.push({
							formType: 'sidebarSubscribe',
							formId: 'sidebarSubscribe',
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// cta contact form
	$('.ctaForm').each(function(){
		$(this).validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).addClass('error');
				$(element).parents('.form-contact__field-container').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).removeClass('error');
				$(element).parents('.form-contact__field-container').removeClass('error');
			},
			errorPlacement: function(error, element) { },
			submitHandler: function(form) {
				var serializedForm = $(form).serialize(),
					$thisForm = $('#' + this.currentForm.id),
					$submitButton = $thisForm.find('input[type=submit]'),
					submitValue = $submitButton.val(),
					ajaxCall = $thisForm.attr('data-ajax-url');
				fadeLoader('in');
				$submitButton.prop('disabled', true).val('Submitting...');
				$.ajax({
					type: 'POST',
					url: '/ajax/' + ajaxCall + '.php',
					data: serializedForm,
					dataType: 'json',
					success: function(response) {
						fadeLoader('out');
						$submitButton.prop('disabled', false).val(submitValue);
						if (response.status == 'ok') {
							$(form)[0].reset();
							if($thisForm.parents('#modal-enquiry-form-widget').length){
								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $thisForm.parents('#modal-enquiry-form-widget').find(".form-enquiry-secondary__success");
								$thisForm.fadeOut(400,function(){
									// thankyouDiv.find("h4").html(response.message);
									thankyouDiv.find('h2').html(response.message);
									thankyouDiv.removeClass('inactive');
								});
							}
							else {
								if ($thisForm.find('.form-contact__content').length > 0) {
									$thisForm.parent().next('.form-contact__thank-you').children('h2').html(response.message);
									$thisForm.parent().next('.form-contact__thank-you').removeClass('hidden');
								}
								else {
									$thisForm.find('.form-contact__aside').children('p.thanks').remove();
									$thisForm.find('.form-contact__aside').append('<p class="thanks" style="position: absolute; bottom: 20px; left: 0; ">' +
									response.message + '</p>');
								}
							}
							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});
	});

	$('#mandarinForm').validate({
		errorClass: 'error',
		/*
		highlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').removeClass('error');
		},
		*/
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');
			serializedForm += "&name=" + $thisForm.find('input[name=first_name]').val() + " " + $thisForm.find('input[name=last_name]').val();
			serializedForm += "&url=" + window.location;
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/contact_mandarin.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						//$(form)[0].reset();
						$('#mandarin-thankyou').removeClass('hidden');
						dataLayer.push({
							formType: 'mandarin-enquiry',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});
	
	$('.palmview').validate({
		errorClass: 'error',
		/*
		highlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').removeClass('error');
		},
		*/
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');

			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						//$(form)[0].reset();
						$('.form-contact__thank-you').removeClass('hidden');
						dataLayer.push({
							formType: 'palmview-enquiry',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});
	
	

	$('.cta-signup').validate({
		errorClass: 'error',
		/*
		highlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-contact__row').removeClass('error');
		},
		*/
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');
			serializedForm += "&name=" + $thisForm.find('input[name=first_name]').val() + " " + $thisForm.find('input[name=last_name]').val();
			serializedForm += "&url=" + window.location;
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();

						$('.cta-signup').html('<h2 class="thanks">Thanks for subscribing</h2>');
						dataLayer.push({
							formType: 'cta-signup',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});



	$('.book-callback_form').not('.dvhealth').validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).addClass('error');
				$(element).parents('.form-contact__field-container').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).removeClass('error');
				$(element).parents('.form-contact__field-container').removeClass('error');
			},
			errorPlacement: function(error, element) { },
			submitHandler: function(form) {
				var serializedForm = $(form).serialize(),
					$thisForm = $('#' + this.currentForm.id),
					$submitButton = $thisForm.find('input[type=submit]'),
					submitValue = $submitButton.val(),
					ajaxCall = $thisForm.attr('data-ajax-url');

				$submitButton.prop('disabled', true).val('Submitting...');
				$.ajax({
					type: 'POST',
					url: '/ajax/' + ajaxCall + '.php',
					data: serializedForm,
					dataType: 'json',
					success: function(response) {
						fadeLoader('out');
						$submitButton.prop('disabled', false).val(submitValue);
						if (response.status == 'ok') {
							$(form)[0].reset();

								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $('.book_callback_thank_you');
								$thisForm.fadeOut(400,function(){

									thankyouDiv.fadeIn('slow');
								});

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
						else if (response.status == 'covid') {
							$(form)[0].reset();

								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $('.book_callback_thank_you_error');
								$thisForm.fadeOut(400,function(){

									thankyouDiv.fadeIn('slow');
								});

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});


	$('.dvhealth').validate({
		ignore: [],
  		rules: {
        symptoms: "required",
		exposed: 'required',
		coronavirus: "required",
		overseas: 'required'
    },
  		messages: {
 		 symptoms: "This is a required field",
 		 exposed: "This is a required field",
 		 coronavirus: "This is a required field",
 		 overseas: "This is a required field"
    	},
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).addClass('error');
			console.log(element.type);
			if (element.type === 'radio') {
				 $(element).closest('.competition_radios').addClass('error');
				 if(element.name == 'symptoms'){
				 	$('.symptoms-error').addClass('error');
				 }
				 if(element.name == 'exposed'){
					 $('.exposed-error').addClass('error');
				 }
				 if(element.name == 'coronavirus'){
					 $('.coronavirus-error').addClass('error');
				 }
				 if(element.name == 'overseas'){
					 $('.overseas-error').addClass('error');
				 }


			} else {
				$(element).parents('.form-contact__field-container').addClass('error');
        	}


		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error');

			if (element.type === 'radio') {

                $(element).closest('.competition_radios').removeClass('error');
				 if(element.name == 'symptoms'){
				 	$('.symptoms-error').removeClass('error');
				 }
				 if(element.name == 'exposed'){
					 $('.exposed-error').removeClass('error');
				 }
				 if(element.name == 'coronavirus'){
					 $('.coronavirus-error').removeClass('error');
				 }
				 if(element.name == 'overseas'){

					 $('.overseas-error').removeClass('error');
				 }

            }
            else
            { // This is the default behavior
               $(element).parents('.form-contact__field-container').removeClass('error');
            }


		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val(),
				ajaxCall = $thisForm.attr('data-ajax-url');

			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/' + ajaxCall + '.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#modal-enquiry-form-widget').addClass('small-modal');
						var thankyouDiv = $('.book_callback_thank_you');
						$thisForm.fadeOut(400,function(){

							thankyouDiv.fadeIn('slow');
						});

						dataLayer.push({
							formType: 'ctaForm',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else if (response.status == 'covid') {
							$(form)[0].reset();

								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $('.book_callback_thank_you_error');
								$thisForm.fadeOut(400,function(){

									thankyouDiv.fadeIn('slow');
								});

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	$('.filters input[type=radio]').on('click', function() {
		dataLayer.push({
			formType: 'filter',
			formId: 'filter',
			event: 'searchPanelEvent',
			searchFeature: $(this).attr('id')
		});
	});
	$('.filters select').on('change', function() {
		dataLayer.push({
			formType: 'filter',
			formId: 'filter',
			event: 'searchPanelEvent',
			searchFeature: $(this).attr('name')
		});
	});
	$('.filters input[type=checkbox]').on('click', function() {
		if ($(this).is(':checked')) {
			dataLayer.push({
				formType: 'filter',
				formId: 'filter',
				event: 'searchPanelEvent',
				searchFeature: $(this).attr('id')
			});
		}
	});

	$('.house-and-land-landing-page .main-part, .house-and-land .main-inner, #homes-on-display, .display-for-sale_detail .main-inner, .display-for-sale .main-inner').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.product-disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});

	$('#homes-on-display, .search-results .hl-search-result').on('click', '.button__see-terms', function(e) {
		e.preventDefault();
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({
					page_id: pageID
				}),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					} else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});


	$('.readybuilt-landing .main-part, .search-results .grid_rb').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/rb_get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});
	$('.display-for-sale .main-part').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});


	$('.readybuilt-filter__select').on('change', '#readybuilt-suburb, #readybuilt-estate', function (e) {
		var $select  = $(this),
			selectID = $(this).attr('id'),
			range    = $(this).attr('data-range-id'),
			isParent = $(this).attr('data-is-parent'),
			theData;

		if (selectID == 'readybuilt-suburb') {
			theData = { range: range, suburb: $select.val(), isParent: isParent };
		}
		else if (selectID == 'readybuilt-estate') {
			theData = { range: range, estate: $select.val(), isParent: isParent };
		}

		$.ajax({
			type: 'POST',
			url: '/ajax/rb_listing.php',
			data: theData,
			dataType: 'html',
			success: function(html) {
				$('.readybuilt-table__container').html(html);
				propertyItemSlider();
			}
		});
	});
	$('.readybuilt-filter__regions').on('change', 'input:radio', function (e) {
		var $radio   = $(this),
			radioID  = $(this).attr('id'),
			range    = $(this).attr('data-range-id'),
			isParent = $(this).attr('data-is-parent'),
			theData = { range: range, isParent: isParent, region: $radio.val() };

		$.ajax({
			type: 'POST',
			url: '/ajax/rb_listing.php',
			data: theData,
			dataType: 'html',
			success: function(html) {
				$('.readybuilt-table__container').html(html);
				propertyItemSlider();
			}
		});
	});
	$('.readybuilt-table__container, .readybuilt-listing, .readybuilt-grid, .selected_rb_packages, body.readybuilt-landing .houses-filter__list .ajax-content-here').on('click', 'a.readybuilt-table__enquire, a.readybuilt-listing__enquire, a.readybuilt-grid__enquire', function(e) {
		// set some stuff in the form
		var thisAddress = $(this).attr('data-address-enquiry'),
			thisAddressDisplay = $(this).attr('data-address-display'),
			thisEstate = $(this).attr('data-estate'),
			thisEnquiryEmailValueID = $(this).attr('data-email-id');

		$('#address_of_enquiry').val(thisAddress);

		$('#rb_modal_override_region_id').val('');
		$('#rb_modal_override_sales_centre_id').val('');
		$('#field-region').parents('.form-controls custom--select-tertiary').show();
		$('#field-region').parents('.form-enquiry-secondary__field-container').show();
		$('#field-sales-centre').parents('.form-controls custom--select-tertiary').show();
		$('#field-sales-centre').parents('.form-enquiry-secondary__field-container').show();
		if ($(this).hasClass('readybuilt-grid__enquire') && $('body').hasClass('readybuilt-landing')) {
			var thisRegion = $(this).attr('data-region-id'),
				thisSalesCentre = $(this).attr('data-sales-centre-id');
			if (typeof thisSalesCentre != 'undefined') {
				
				if (thisSalesCentre.length > 0) {
					$('#rb_modal_override_sales_centre_id').val(thisSalesCentre);
					$('#field-region').parents('.form-controls custom--select-tertiary').hide();
					$('#field-region').parents('.form-enquiry-secondary__field-container').hide();
					$('#field-sales-centre').parents('.form-controls custom--select-tertiary').hide();
					$('#field-sales-centre').parents('.form-enquiry-secondary__field-container').hide();
				}
			}
		}

		$('.form-enquiry-secondary__aside em').html(thisAddressDisplay);
		$('#readybuilt-enquiry-region').val(thisEstate);
		$('#readybuilt-enquiry-email-id').val(thisEnquiryEmailValueID);
		$('#modal-enquiry-form').modal();
		return false;
	});
	propertyItemSlider();

	$('.readybuilt-table__container, .readybuilt-listing, .readybuilt-grid, .selected_rb_packages, body.readybuilt-landing .houses-filter__list .ajax-content-here').on('click', 'a.readybuilt-table__call-now, a.readybuilt-listing__call-now, a.readybuilt-grid__call-now', function(e) {
		var thisPageID = $(this).attr('data-page-id'),
			salespersonName = $(this).attr('data-salesperson-name'),
			salespersonPhone = $(this).attr('data-salesperson-phone'),
			salespersonImageUrl = $(this).attr('data-salesperson-image');
			$('.call-now-modal .salesperson-image').attr('src', salespersonImageUrl);
			$('.call-now-modal .salesperson-name').html(salespersonName);
			$('.call-now-modal .salesperson-phone a').html(salespersonPhone).attr('href', 'tel:+61' + salespersonPhone.replace(/^0/, '').replace(/\s|\-|\.|\(|\)/g, ''));
			$('#modal-call-now').modal();
		return false;
	});

	// readybuilt townhomes
	$('.section-townhomes__tab-anchors').on('click', 'a', function() {
		var selectedTab       = $(this).attr('data-tab'),
			$showTab          = $('#townhome-tab-' + selectedTab),
			$currentTabAnchor = $(this).parents('ul').children('.selected'),
			$currentTab       = $('.section-townhomes__tabs .section-townhomes__tab.selected');
		$currentTabAnchor.removeClass('selected');
		$currentTab.removeClass('selected');
		$(this).parent('li').addClass('selected');
		$showTab.addClass('selected');
		return false;
	});
	$('.section-townhomes-listings__list').on('click', 'a.expand-townhomes-listing', function() {
		var $thisAnchor   = $(this),
			toExpand      = $(this).attr('data-expand'),
			expandPageID  = toExpand.replace('listing-expand-', ''),
			$toExpand     = $('#' + toExpand),
			$floorplanDiv = $('#floorplan-' + expandPageID),
			$floorplanObj = $toExpand.find('.floorplan__svg'),
			$loaderPanel  = $floorplanDiv.children('.loader-panel');

		if ($toExpand.hasClass('expanded')) {
			// close
			$toExpand.removeClass('expanded');
			$thisAnchor.html('View');
		}
		else {
			// open
			$toExpand.addClass('expanded');
			$thisAnchor.html('Hide');
			if (!$floorplanObj.hasClass('loaded')) {
				fadeLoader('in', null, $loaderPanel);
				var svgID = '#' + $floorplanObj.attr('id'),
					svgPath = $floorplanObj.attr('data-image');
				$floorplanObj.attr('data', svgPath);
				$floorplanObj[0].addEventListener('load', function() {
					rb_townhome_svgPanZoom(svgID);
					fadeLoader('out', null, $loaderPanel)
					$floorplanObj.addClass('loaded');
				});
			}
		}
		return false;
	});

	if ($('body').hasClass('house-and-land-landing-page')) {
		if ($('#special-offers-section').length > 0) {
			$('#special-offers-section').slick();
		}
	}

	if($('body.new-homes_series').length > 0 || $('body.house-and-land').length > 0) {
		$('.homes-on-display .slick-container').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			autoplay: false,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						dots: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				}
			]
		});
		updateFinalSelection();
	}

	// loader
	if ($('.loader-panel').length > 0) {
		loaderInit();
	}

	// compare section
	$('.home-type__select').on('click', 'a', function() {
		var $homeType    = $(this),
			rootID       = $(this).attr('data-root-id'),
			homeType     = $(this).attr('data-home-type'),
			$leftSelect  = $(this).parents('.compare-box__up').find('.select-left'),
			$rightSelect = $(this).parents('.compare-box__up').find('.select-right'),
			colNum       = $(this).parents('.compare-col').attr('data-compare-column');

		$('#compare-content-' + colNum).slideUp(function() {
			$('#compare-content-' + colNum).html('');
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/compare_dropdowns.php',
				data: ({ homeType: homeType, rootID: rootID, side: 'left' }),
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					if (response.status == 'ok') {
						if (response.results.length > 0) {
							$leftSelect.children().remove();
							$leftSelect.append('<option value="">' + $homeType.attr('data-left-placeholder') + '</option>');

							for (i in response.results) {
								var thisResult = response.results[i];
								$leftSelect.append('<option value="' + thisResult.id + '">' + thisResult.name + '</option>');
							}
							$leftSelect.attr('data-home-type', homeType).parent().removeClass('custom--select-tertiary-inactive');

							$rightSelect.children().remove();
							$rightSelect.append('<option value="">' + $homeType.attr('data-right-placeholder') + '</option>');
							$rightSelect.attr('data-home-type', homeType).parent().addClass('custom--select-tertiary-inactive');
						}
						$homeType.parents('.home-type__select').find('a.active').removeClass('active');
						$homeType.addClass('active');
					}
					else {
						alert('Sorry, but there was an error while handling your request. ' + response.message);
					}
				}
			});
		});
		return false;
	});

	$('.compare-box__up').on('change', 'select.select-left', function() {
		var colNum       = $(this).parents('.compare-col').attr('data-compare-column'),
			homeType     = $(this).attr('data-home-type'),
			$leftSelect  = $(this),
			$rightSelect = $(this).parents('.row--seven').find('select.select-right'),
			$homeType    = $(this).parents('.compare-box__up').find('.home-type__select a.active'),
			rootID       = $homeType.attr('data-root-id');

		if (!$(this).parent().hasClass('custom--select-tertiary-inactive')) {
			// populate the right side
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/compare_dropdowns.php',
				data: ({ homeType: homeType, rootID: rootID, side: 'right', selectedLeft: $(this).val() }),
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					if (response.status == 'ok') {
						$rightSelect.attr('data-home-type', homeType).children().remove();
						$rightSelect.append('<option value="">' + $homeType.attr('data-right-placeholder') + '</option>');
						if (response.results.length > 0) {
							for (var i in response.results) {
								var thisResult = response.results[i];
								if (typeof thisResult.children != 'undefined') {
									if (thisResult.children.length > 0) {
										var optgroupString = '<optgroup label="' + thisResult.name + '">';
										for (var j in thisResult.children) {
											optgroupString += '<option value="' + thisResult.children[j].id + '">' + thisResult.children[j].name + '</option>';
										}
										optgroupString += ' </optgroup>';
										$rightSelect.append(optgroupString);
									}
								}
								else {
									$rightSelect.append('<option value="' + thisResult.id + '">' + thisResult.name + '</option>');
								}
							}
						}
						$rightSelect.parent().removeClass('custom--select-tertiary-inactive');
					}
					else {
						alert('Sorry, but there was an error while handling your request. ' + response.message);
					}
				}
			});
		}
		else return false;
	});
	$('.compare-box__up').on('change', 'select.select-right', function() {
		var $homeType   = $(this).parents('.compare-box__up').find('.home-type__select a.active'),
			rootID      = $homeType.attr('data-root-id'),
			$leftSelect = $(this).parents('.row--seven').find('select.select-left'),
			parentID    = $leftSelect.val(),
			colNumber   = $(this).parents('.compare-col').attr('data-compare-column');

		if (!$(this).parent().hasClass('custom--select-tertiary-inactive')) {
			$('#compare-content-' + colNumber).show();
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/get_compare.php',
				data: ({ id: $(this).val(), leftID: parentID, rootID: rootID, colNum: colNumber }),
				dataType: 'html',
				success: function(html) {
					fadeLoader('out');
					$('#compare-content-' + colNumber).html(html);
					$('#compare-col_' + colNumber).find('.compare-col__close').addClass('on');

					setTimeout(function() {
						$(window).lazyLoadXT();
					}, 50);

					var svgContainer = document.getElementById('svg' + colNumber);
					if (typeof svgContainer != 'undefined' && svgContainer != null ) {
						svgContainer.onload = function() {
							var svgDoc = svgContainer.contentDocument;
							if (svgDoc != null) {
								if (typeof svgDoc == 'object') {
									var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
									$groundLevel.attr('display', 'block').attr('class', '');
									$groundLevel.children('g').each(function() {
										if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});
									var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
									if (typeof $firstLevel != 'undefined') {
										$firstLevel.attr('display', 'none').attr('class', '');
										$firstLevel.children('g').each(function() {
											if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
											else $(this).attr('display', 'none').attr('class', '');
										});
									}
								}
							}
						}
					}
					compareEvenHeights('.compare--boxes .compare-col');
				}
			});
		}
		else return false;
	});

	$('.compare--boxes').on('click', 'a.compare-col__close', function() {
		var $thisObj       = $(this),
			$thisObjParent = $thisObj.parent(),
			colNum         = $thisObjParent.attr('data-compare-column');

		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: '/ajax/compare_remove.php',
			data: ({ columnNumber: colNum }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					$thisObj.next().find('.home-type__select a.active').removeClass('active');

					var $leftSelect  = $thisObjParent.find('select.select-left'),
						$rightSelect = $thisObjParent.find('select.select-right')

					$leftSelect.attr('data-home-type', '').children().remove().append('<option value=\"\">Select a series</option>');
					$leftSelect.append('<option value=\"\">Select a series</option>');
					$rightSelect.attr('data-home-type', '').children().remove();
					$rightSelect.append('<option value=\"\">Select a design</option>');
					$('#compare-content-' + colNum).html('');
					$thisObj.removeClass('on');
				}
				else {
					alert(response.message);
				}
				fadeLoader('out');
			}
		});
		return false;
	});


	$('.close-compare').on('click', function() {
		$('.compare-tooltip, .close-compare').fadeOut();
		return false;
	});

	if($('body').hasClass('compare')) {
		$('.compare-col').each(function() {
			var $compareImgWrapper = $(this).find('.compare-img-wrapper');
			if ($compareImgWrapper.length > 0) {
				var svgID = $compareImgWrapper.children('object').attr('id');
				var svgContainer = document.getElementById(svgID);
				if (typeof svgContainer != 'undefined' && svgContainer != null ) {
					svgContainer.addEventListener('load', function() {
						var svgDoc = svgContainer.contentDocument;
						if (svgDoc != null) {
							if (typeof svgDoc == 'object') {
								// check to see if there's more than one level for this floor plan
								var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
								$groundLevel.attr('display', 'block').attr('class', '');
								$groundLevel.children('g').each(function() {
									if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});

								var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
								if (typeof $firstLevel != 'undefined') {
									$firstLevel.attr('display', 'none').attr('class', '');
									$firstLevel.children('g').each(function() {
										if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});
 								}
							}
						}
					}, false);
				}
			}
			compareEvenHeights('.compare--boxes .compare-col');
		});

		// refresh all SVGs again just to clean up some things
		var unintObjTimeout = setTimeout(function () {
			$('.compare-col').each(function() {
				var $uninitObj = $(this).find('object:not(.svg-loaded)');
				if ($uninitObj.length > 0) {
					var svgID = $uninitObj.attr('id');
					var svgContainer = document.getElementById(svgID);
					if (typeof svgContainer != 'undefined' && svgContainer != null ) {
						var svgDoc = svgContainer.contentDocument;
						if (svgDoc != null) {
							if (typeof svgDoc == 'object') {
								// check to see if there's more than one level for this floor plan
								var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
								$groundLevel.attr('display', 'block').attr('class', '');
								$groundLevel.children('g').each(function() {
									if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});

								var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
								if (typeof $firstLevel != 'undefined') {
									$firstLevel.attr('display', 'none').attr('class', '');
									$firstLevel.children('g').each(function() {
										if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});
								}
							}
						}
					}
				}
			});
		}, 1000);

		$('.compare-col').on('click', 'a.compare-box__floorplan-level', function() {
			var selectedLevel      = $(this).attr('data-level'),
				$compareCol        = $(this).parents('.compare-col'),
				$compareImgWrapper = $compareCol.find('.compare-img-wrapper'),
				svgID              = $compareImgWrapper.find('object').attr('id'),
				svgContainer       = document.getElementById(svgID);

			if (!$(this).hasClass('disabled')) {
				if (typeof svgContainer != 'undefined' && svgContainer != null ) {
					var svgDoc = svgContainer.contentDocument;
					if (svgDoc != null) {
						if (typeof svgDoc == 'object') {
							var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
							$groundLevel.children('g').each(function() {
								if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
								else $(this).attr('display', 'none').attr('class', '');
							});

							var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
							if (typeof $firstLevel != 'undefined') {
								$firstLevel.children('g').each(function() {
									if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});
							}

							if (selectedLevel == 'ground') {
								$groundLevel.attr('display', 'block').attr('class', '');
								$firstLevel.attr('display', 'none').attr('class', '');
							}
							else {
								$groundLevel.attr('display', 'none').attr('class', '');
								$firstLevel.attr('display', 'block').attr('class', '');
							}
						}
					}
				}
			}

			$compareCol.find('a.compare-box__floorplan-level').removeClass('active');
			$(this).addClass('active');
			return false;
		});

		$('.compare--boxes').on('click', 'a.expand-icon', function(e) {
			e.preventDefault();
			var $column        = $(this).parents('.compare-col'),
				columnNumber   = $column.attr('data-compare-column'),
				$floorplanData = $column.find('.floorplan-data'),
				$zoomModal     = $('.zoom-in-modal'),
				$zoomModalLeft = $zoomModal.find('.left.block');

			var placeholders = ['zoom-houseName', 'zoom-totalSq', 'zoom-totalMSq', 'zoom-lotWidth', 'zoom-lotDepth', 'zoom-bedrooms',
				'zoom-bathrooms', 'zoom-powderrooms', 'zoom-cars', 'zoom-livingareas', 'zoom-fullDimensions'];
			for (i in placeholders) {
				var placeholder = placeholders[i];
				if ($('#' + placeholder).length > 0) {
					var value = $floorplanData.find('#' + placeholder.replace('zoom-', 'data-' + columnNumber + '-')).html();
					if (typeof value != 'undefined') {
						if (value.length > 0) $('#' + placeholder).html(value).show();
						else $('#' + placeholder).hide();
					}
					else $('#' + placeholder).hide();
				}
			}

			$('body').addClass('no-scroll');

			if ($('#zoom-lotWidth').html().length == 0) $('.zoom-lotDimensions').hide();
			else $('.zoom-lotDimensions').show();
			$('#zoomfloorplan').parent('.right.block').css('visibility', 'hidden');

			// place SVG in full screen container
			var svg = $('#svg' + columnNumber).attr('data');
			$('#zoom_svg').attr('onload', 'svgOnLoad("zoom_svg");').attr('data', svg);

			$('.zoom-in-modal .center .right.block').css('visibility', 'visible');
			$('.zoom-in-modal').css("visibility", "visible").fadeTo(500,1);
			return false;
		});
	}

	// visualizer
	var isTV = false;
	if ($('body').hasClass('visualiser') || $('body').hasClass('visualiser_iframe')) isTV = true;

	$('.visualiser-modal').on('click', '.btn-danger', function() {
		$('#visualiser--terms').hide();
		return false;
	});
	$('.visualiser-modal').on('click', '.btn-success', function() {
		$('#visualiser--terms').hide().css('visibility', 'hidden');
		var categoryID = $('#visualiser--terms').data('visualizer-category');
		$('.visualizer__initial .visualizer__category-select').removeClass('showModal');
		$('.visualizer__category-select[data-visualizer-category=' + categoryID + ']').trigger('click');
		return false;
	});
	$('.visualizer__initial').on('click', '.visualizer__category-select', function() {
		if ($(this).hasClass('showModal')) {
			var categoryID = $(this).data('visualizer-category');
			$('#visualiser--terms').data('visualizer-category', categoryID).css("visibility","visible").css('opacity', 1).show();
		}
		else {
			//adding classes to hide previous
			$('.visualizer__initial').addClass('hidden');
			$('.visualizer__description').addClass('hidden');

			var categoryID = $(this).attr('data-visualizer-category');
			if (!$('#visualizer__category-' + categoryID).hasClass('open')) {
				$('.visualizer__initial a.on').removeClass('on');
				$('.visualizer__category.open').hide().removeClass('open');
				$('#visualizer__category-' + categoryID).show().addClass('open');
				$(this).addClass('on');
			}
		}
		if (isTV) {
			var hash = $(this).attr('data-visualizer-category');
			History.pushState(hash, null, '#' + hash);
			$('header .header__back', window.parent.document).show();
		}

		return false;
	});
	$('.visualizer__select-subcategory').on('click', '.visualizer__category-select', function() {
		var $thisObj      = $(this),
			subcategoryID = $thisObj.attr('data-visualizer-category');

			//adding classes to hide previous
			$thisObj.parents('.visualizer__select-subcategory').addClass('hidden');

		if (!$('#visualizer__category-' + subcategoryID).hasClass('open')) {
			$('.visualizer__select-subcategory a.on').removeClass('on');
			$('.visualizer__subcategory.open').hide().removeClass('open');
			$('#visualizer__category-' + subcategoryID).show().addClass('open');
			var $centerBlock = $thisObj.parents('.visualizer__category').children('.center-block');
			$centerBlock.children('h2').addClass('hidden');
			$centerBlock.children('h3').removeClass('hidden');
			$thisObj.addClass('on');
		}

		if (isTV) {
			var hash = $thisObj.attr('data-visualizer-category');
			History.pushState(hash, null, '#' + hash);
			$('header .header__back', window.parent.document).show();
		}

		return false;
	});

	$('.visualizer__buttons-back').click(function(){
		if (isTV) {
			History.back();
		}
		else {
			if($('.visualizer__subcategory.open').length){
				var $centerBlock = $('.visualizer__category.open').children('.center-block');
				$centerBlock.children('h2').removeClass('hidden');
				$centerBlock.children('h3').addClass('hidden');

				$('.visualizer__subcategory.open').hide().removeClass('open');
				$('.visualizer__select-subcategory.hidden').hide().removeClass("hidden").show();
			}
			else if($('.visualizer__category.open').length){
				$('.visualizer__category.open').hide().removeClass('open');
				$('.visualizer__description').show().removeClass('hidden');
				$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
			}
		}
	});

	$('.visualizer__buttons-start-again').click(function(){
		if($('.visualizer__subcategory.open').length){
			$('.visualizer__category').hide().removeClass("open").removeClass("hidden")
			$('.visualizer__subcategory.open').hide().removeClass('open');
			$('.visualizer__select-subcategory.hidden').removeClass("hidden");
			$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
		}else if($('.visualizer__category.open').length){
			$('.visualizer__subcategory.open').hide().removeClass('open');
			$('.visualizer__select-subcategory.hidden').removeClass("hidden");
			$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
		}
	});

	$('.visualizer__kiosk').on('click', function() {
		fadeLoader('in');
	});

	if (isTV) {
		fadeLoader('in');
		function visualiserHash(hash) {
			var currentHash = hash.replace('#', '');
			if (currentHash == "") {
				if($('.visualizer__subcategory.open').length){
					$('.visualizer__category').hide().removeClass("open").removeClass("hidden")
					$('.visualizer__subcategory.open').hide().removeClass('open');
					$('.visualizer__select-subcategory.hidden').removeClass("hidden");
					$('.visualizer__initial, .visualizer__description').hide().removeClass("hidden").show().addClass('on');
				}else if($('.visualizer__category.open').length){
					$('.visualizer__category.open').hide().removeClass('open');
					$('.visualizer__initial, .visualizer__description').hide().removeClass("hidden").show().addClass('on');
				}
				$('header .header__back', window.parent.document).show();
			}
			else {
				var anchor = 'a[data-visualizer-category="' + currentHash +'"]';
				// console.log('anchor: ' + anchor);
				// $(anchor).click();
				var $thisCategory = $('#visualizer__category-' + currentHash);
				$('.visualizer__initial, .visualizer__description').hide();
				$('.visualizer__category.open').removeClass('open').hide();
				$thisCategory.addClass('open').show();
				if ($thisCategory.hasClass('visualizer__subcategory')) {
					$thisCategory.parent('.visualizer__category').show().addClass('open').children('.visualizer__select-subcategory').addClass('hidden');
				}
				else {
					$thisCategory.children('.visualizer__select-subcategory').removeClass('hidden');
					$thisCategory.children('.visualizer__subcategory.open').removeClass('open').hide();
				}
				$('header .header__back', window.parent.document).show();
			}
			fadeLoader('out');
		}

		window.onhashchange = function() {
			visualiserHash(window.location.hash);
		};
		if (window.location.hash) {
			visualiserHash(window.location.hash);
		}
		else {
			fadeLoader('out');
		}
		if (window.location.hash == "") $('header .header__back', window.parent.document).show();
	}

	var postcodeXHR;
	var postcodeAjaxActive = false;
	function capturePostcode() {
		var enteredVal   = $(this).val(),
			$resultsList = $('#postcode__search-results'),
			$buildYes = $('#postcode__build-notice'),
			$buildNo  = $('#postcode__no-build-notice');

		if (postcodeAjaxActive === true) postcodeXHR.abort();
		postcodeAjaxActive = true;
		$buildYes.addClass('hidden');
		$buildNo.addClass('hidden');

		$.ajax({
			type: 'POST',
			url: '/ajax/where_we_build_postcode.php',
			data: { search: enteredVal },
			dataType: 'json',
			success: function(response) {
				// clear out old list
				$resultsList.children().remove();
				if (response.status == 'ok') {
					if (response.results.length > 0) {
						for (i in response.results) {
							thisResult = response.results[i];
							var liClass = "";
							if (thisResult.build_status == 'build') liClass = " class=\"build\"";

							var innerHTML = '<li' + liClass + '><a href=\"#\" class=\"postcode_search-result\" data-build-status=\"' +
								thisResult.build_status + '\" data-postcode=\"' + thisResult.postcode + '\" data-suburb=\"' + thisResult.suburb +
								'\">' + thisResult.suburb + ' (' + thisResult.postcode + ')</a></li>';
							$resultsList.append(innerHTML);
						}
						$resultsList.addClass('active');
					}
					else {
						$resultsList.removeClass('active');
					}
				}
				else {
					$resultsList.removeClass('active');
				}
				postcodeAjaxActive = false;
			}
		});
	}

	var appointmentDates = [];
	if ($('#d365o-appointment').length > 0 || $('#d365o-search').length > 0) {
		var foundAppointments;
		// booking an appointment from a display centre page
		$('#d365o-appointment, #d365o-search').each(function() {
			$(this).validate({
				errorClass: 'error',
				highlight: function(element, errorClass) {
					$(element).parents('.form-group').addClass('error');
				},
				unhighlight: function(element, errorClass) {
					$(element).parents('.form-group').removeClass('error');
				},
				errorPlacement: function(error, element) { },
				submitHandler: function(form) {
					var $form           = $('#' + form.id),
						serializedForm  = $form.serialize(),
						$submitButton   = $form.find('button[type=submit]'),
						submitValue     = $submitButton.html(),
						submittingValue = 'Submitting...';

					if (form.id == 'd365o-search') submittingValue = "Searching...";

					$submitButton.prop('disabled', true).html(submittingValue);
					$.ajax({
						type: 'POST',
						url: '/ajax/appointments.php',
						data: serializedForm,
						dataType: 'json',
						success: function(response) {
							$submitButton.prop('disabled', false).html(submitValue);
							if (response.status == 'ok') {
								$(form)[0].reset();

								if (form.id == 'd365o-appointment') {
									var $thankyouDiv = $('#d365o-appointment').next('.form-booking__thank-you');
									$thankyouDiv.find('h2').html(response.message);
									$thankyouDiv.removeClass('hidden');

									   dataLayer.push({
					                        formType: 'displayAppointment',
					                        formId: 'bookAppointment',
					                        event: 'formSubmissionSuccess'
					                    });



								}
								else if (form.id == 'd365o-search') {
									var foundAppointment = response.result,
										$editForm        = $('#d365o-appointment-edit');

									// populate the form
									var appointmentDate = foundAppointment.AppointmentContract.Date.substr(0, 10),
										appointmentTime = foundAppointment.AppointmentContract.WorkerRecId + '-'
														  + foundAppointment.AppointmentContract.FromTime + '-'
														  + foundAppointment.AppointmentContract.ToTime;
									$editForm.find('#edit-inputFirstName').val(foundAppointment.OpportunityStagingContract.FirstName);
									$editForm.find('#edit-inputLastName').val(foundAppointment.OpportunityStagingContract.LastName);
									$editForm.find('#edit-inputEmail').val(foundAppointment.OpportunityStagingContract.Email);
									$editForm.find('#edit-inputPhone').val(foundAppointment.OpportunityStagingContract.MobilePhone);
									$editForm.find('#edit-inputEnquiry').val(foundAppointment.OpportunityStagingContract.Subject);
									$editForm.find('#edit-inputLocation').val(foundAppointment.OpportunityStagingContract.SalesUnit);
									$editForm.find('#edit-appointment_date').attr('data-appointment-date', appointmentDate);
									$editForm.find('#edit-appointment_time').attr('data-appointment-time', appointmentTime).attr('data-update', 1);
									$editForm.find('#edit-inputHowDidYouHear').val(foundAppointment.OpportunityStagingContract.WhereDidYouHear);
									$editForm.find('#edit-inputMessage').val(foundAppointment.OpportunityStagingContract.Comments);
									$editForm.find('#edit-inputLocation').trigger('change');
									$editForm.parent('.booking-list').addClass('active');
								}
							}
							else {
								alert(response.message);
							}
						}
					});
				}
			});
		});
		$('#edit-appointment-delete').on('click', function() {
			if (confirm('Are you sure you want to delete your appointment?')) {
				$('#edit-api-action').val(2);
				return true;
			}
			else return false;
		});
		$('#edit-appointment-update').on('click', function() {
			$('#edit-api-action').val(1);
			return true;
		});
		$('#d365o-appointment-edit').validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).parents('.form-group').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).parents('.form-group').removeClass('error');
			},
			errorPlacement: function(error, element) { },
			submitHandler: function(form) {
				var serializedForm = $('#d365o-appointment').serialize(),
					$submitButton = $('#d365o-appointment button[type=submit]'),
					submitValue = $submitButton.html();
				$submitButton.prop('disabled', true).html('Submitting...');
				$.ajax({
					type: 'POST',
					url: '/ajax/appointments.php',
					data: serializedForm,
					dataType: 'json',
					success: function(response) {
						$submitButton.prop('disabled', false).html(submitValue);
						if (response.status == 'ok') {
							var $thankyouDiv = $('#d365o-appointment-edit').next('.form-booking__thank-you');
							$thankyouDiv.find('h2').html(response.message);
							$thankyouDiv.removeClass('hidden');
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});

		$('.main-inner').on('change', '#inputLocation, #edit-inputLocation', function(trigger) {
			// get a list of appointment times
			var $thisObj         = $(this),
				selectedLocation = $thisObj.val(),
				$appointmentDate = $thisObj.parents('form').find('select[name=appointment_date]'),
				$appointmentTime = $thisObj.parents('form').find('select[name=appointment_time]');


			// clear out appointment_date and appointment_time dropdowns, reset to empty
			$appointmentDate.prop('disabled', true).parents('.custom-select').addClass('disabled');
			$appointmentTime.prop('disabled', true).parents('.custom-select').addClass('disabled');

			var $firstDateOption = $appointmentDate.children('option:first-child');
			$appointmentDate.empty().append($firstDateOption);
			var $firstTimeOption = $appointmentTime.children('option:first-child');
			$appointmentTime.empty().append($firstTimeOption);

			fadeLoader('in', null, $thisObj.parents('form').children('.loader-panel'));

			$.ajax({
				type: 'POST',
				url: '/ajax/appointments.php',
				data: {
					action: 'getAppointments',
					location: selectedLocation
				},
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						// reset appointmentDates array to empty
						appointmentDates = [];
						for (i in response.result) {
							var foundKey = foundTime = -1;

							thisResult = response.result[i];
							// needs moment.min.js, recompile gulp js:vendor to get this in the compiled js.
							thisDate = moment(thisResult.Date).format('YYYY-MM-DD');

							if (appointmentDates.length > 0) {
								// search the appointmentDates store
								for (j in appointmentDates) {
									var thisAppointmentDate = appointmentDates[j];
									if (thisAppointmentDate.date == thisDate) {
										foundKey = j;
									}
								}
								if (foundKey > -1) {
									// go through the times now
									for (k in appointmentDates[foundKey].times) {
										var thisAppointmentTime = appointmentDates[foundKey].times[k];
										if (
											thisAppointmentTime.fromTime == thisResult.FromTime &&
											thisAppointmentTime.toTime   == thisResult.ToTime
										) {
											foundTime = k;
										}
									}
									if (foundTime > -1) {
										appointmentDates[foundKey].times[foundTime].workerRecId.push(thisResult.WorkerRecId);
									}
									else {
										appointmentDates[foundKey].times.push({
											'workerRecId': [thisResult.WorkerRecId],
											'fromTime': thisResult.FromTime,
											'toTime': thisResult.ToTime
										});
									}
								}
							}

							if (foundKey == -1) {
								// just append it to the end of the array
								appointmentDates.push({
									'date': thisDate,
									'times': [{
										'workerRecId': [thisResult.WorkerRecId],
										'fromTime': thisResult.FromTime,
										'toTime': thisResult.ToTime
									}]
								});
							}
						}
						// populate the appointment_date dropdown list
						for (i in appointmentDates) {
							var thisAppointmentDate = appointmentDates[i];
							$appointmentDate.append('<option value="' + thisAppointmentDate.date + '">' + moment(thisAppointmentDate.date).format('DD/MM/YYYY') + '</option>');
						}
						$appointmentDate.prop('disabled', false).parents('.custom-select').removeClass('disabled');

						if ($thisObj.attr('id') == 'edit-inputLocation') {
							$('#edit-appointment_date').trigger('update').trigger('change');
							if ($('#edit-appointment_time').attr('data-update') == 1) {
								$('#edit-appointment_time').removeAttr('data-update').trigger('update');
							}
						}

						fadeLoader('out');
					}
					else {
						fadeLoader('out');
						alert(response.message);
					}
				}
			});
		});
		$('.main-inner').on('change', '#appointment_date, #edit-appointment_date', function() {
			var thisDate = $(this).val(),
				$appointmentTime = $(this).parents('.row').find('select[name=appointment_time]');

			// reset $appointmentTime
			$appointmentTime.prop('disabled', true).parents('.custom-select').addClass('disabled');
			var $firstTimeOption = $appointmentTime.children('option:first-child');
			$appointmentTime.empty().append($firstTimeOption);

			// locate the date node in appointmentDates array
			for (i in appointmentDates) {
				thisAppointmentDate = appointmentDates[i];
				if (thisAppointmentDate.date == thisDate) {
					// found the right node! now push all the times onto the #appointment_time dropdown
					for (j in thisAppointmentDate.times) {
						// pick a random WorkerRecID
						var workerRecId = thisAppointmentDate.times[j].workerRecId[Math.floor(Math.random() * thisAppointmentDate.times[j].workerRecId.length)];

						var thisVal = workerRecId + '-' + thisAppointmentDate.times[j].fromTime + '-' + thisAppointmentDate.times[j].toTime,
							thisLabel = thisAppointmentDate.times[j].fromTime + ' to ' + thisAppointmentDate.times[j].toTime;
						$appointmentTime.append('<option value=\"' + thisVal + '\">' + thisLabel + '</option>');
					}
				}
			}
			$appointmentTime.prop('disabled', false).parents('.custom-select').removeClass('disabled');
		});
	}

	$('input#postcodeSuburbSearch').keyup($.debounce(250, capturePostcode));
	$('#postcode__search-form').on('submit', function(e) {
		e.preventDefault(e);
	});
	$('input#postcodeSuburbSearch').on('focus', function() {
		$(this).parents('form').removeClass('build');
	});
	$('#postcode__search-results').on('click', 'a', function() {
		var postcode    = $(this).attr('data-postcode'),
			suburb      = $(this).attr('data-suburb'),
			buildStatus = $(this).attr('data-build-status'),
			$searchBox  = $('#postcodeSuburbSearch'),
			$results    = $('#postcode__search-results'),
			$buildYes   = $('#postcode__build-notice'),
			$buildNo    = $('#postcode__no-build-notice');

		$searchBox.val(suburb.toUpperCase() + ' (' + postcode + ')');
		if (buildStatus == 'build') {
			// Henley build in this suburb
			// get the lat / long of the postcode
			var geolocateUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=" +
							   encodeURIComponent(suburb) + ",%20VIC&key=AIzaSyDf_6FhWxEJHslCmiF24nOQ68yrqZkK3r4";
			var pinLat, pinLong, marker;
			$.ajax({
				type: 'GET',
				url: geolocateUrl,
				dataType: 'json',
				success: function(response) {
					if (response.status == 'OK') {
						pinLat  = response.results[0].geometry.location.lat;
						pinLong = response.results[0].geometry.location.lng;
					}

					google.maps.event.clearListeners(map, 'zoom_changed');
					google.maps.event.clearListeners(map, 'resize');

					// remove old markers from map
					if (typeof marker != 'undefined') {
						for (var i = 0; i < markers.length; i++) {
							markers[i].setMap(null);
						}
					}

					if (typeof pinLat == 'number') {
						// clear old markers out
						if (typeof markers != 'undefined') {
							for (var i = 0; i < markers.length; i++) {
								markers[i].setMap(null);
							}
						}

						// move the map to this lat and long
						map.setCenter({ lat: pinLat, lng: pinLong });
						// drop a pin here
						var marker = new google.maps.Marker({
							position: {
								lat: pinLat,
								lng: pinLong
							},
							map: map,
							title: suburb,
							icon: '/resources/images/postcode_pin.png'
						});
						markers.push(marker);
					}

					$searchBox.parents('form').addClass('build');
					$buildYes.removeClass('hidden');
					$buildNo.addClass('hidden');
				}
			});
		}
		else {
			// Henley don't build in this suburb
			$searchBox.parents('form').removeClass('build');
			$buildYes.addClass('hidden');
			$buildNo.removeClass('hidden');
		}

		// close the results and clear them out
		$results.removeClass('active');
		$results.children().remove();
		return false;
	});

	if ($('body').hasClass('new-homes_series')) {
		// have the floor plan options accordion expanded if viewport is desktop sized
		if ($('.choices_and_add_ons').length > 0) {
			if (window.innerWidth > 1024) {
				$('.choices_and_add_ons').addClass('accordion__section-expanded');
			}
		}

		$('.eligible-for__panel').on('change', 'input.eligible-for__check', function() {
			var pageID          = $(this).attr('data-page-id'),
				$thisParentRoot = $(this).parents('.eligible-for__panel'),
				$price          = $thisParentRoot.find('.eligible-for__price'),
				$priceLabel     = $('#eligible-for__after-grants-' + pageID),
				originalPrice   = $('span.design-price').attr('data-inclusions-price');
			if ($thisParentRoot.find('.eligible-for__check:checked').length > 0) {
				var discounts = 0;
				$thisParentRoot.find('.eligible-for__check:checked').each(function() {
					discounts += parseInt($(this).val());
				});
				$priceLabel.html(priceFormatter.format(originalPrice - discounts));
				$price.show();
			}
			else {
				$price.hide();
				$priceLabel.html(priceFormatter.format(originalPrice));
			}
		});
	}
});

function compareEvenHeights(columns) {
	var divs = ['.compare-box__main', '.compare-box__dimensions', '.compare-box__bottom'],
		$columns = $(columns);
	if ($columns.length > 0) {
		for (i in divs) {
			var thisDiv = divs[i];
			var maxHeight = 0;
			$columns.each(function() {
				var $thisColumn = $(this);
				if ($thisColumn.find(thisDiv).length > 0) {
					var thisHeight = $thisColumn.find(thisDiv).outerHeight();
					if (thisHeight > maxHeight) maxHeight = thisHeight;
				}
			});
			if (maxHeight > 0) {
				$columns.find(thisDiv).each(function() {
					$(this).css('min-height', maxHeight + 'px' );
				});
			}
		}
	}
}

function loaderInit() {
	var $loaderPanel     = $('.loader-panel'),
		$loader          = $loaderPanel.children('.loader');
		loaderTop        = $loaderPanel.offset().top,
		halfLoaderHeight = Math.floor($loader.outerHeight() / 2),
		scrollCutoff     = Math.floor(loaderTop / 2) + halfLoaderHeight,
		bottomCutoff     = loaderTop + $loaderPanel.outerHeight(),
		posPadding       = 55,
		windowHeight     = $(window).height();

	var initialCSS = {
		position: 'absolute',
		top: (Math.floor(scrollCutoff / 2) - posPadding) + 'px',
		bottom: 'auto'
	};
	if ($('body').hasClass('search') || $('body').hasClass('contact') || $('body').hasClass('visualiser_iframe')) {
		initialCSS = {
			position: 'fixed',
			top: '50%',
			bottom: 'auto', marginTop: "-" + halfLoaderHeight + 'px'
		};
	}
	if ($('body').hasClass('compare')) {
		posPadding = 0; // -1 * loaderTop;
		var initialCSS = {
			position: 'absolute',
			top: (Math.floor(scrollCutoff / 2) - posPadding) + 'px',
			bottom: 'auto'
		};
	}
	if ($('body').hasClass('general') || $('body').hasClass('display-centre-single') || $('body').hasClass('new-homes_series')) {
		initialCSS = {
			position: 'absolute',
			top: '50%'
		}
	}

	$loader.css(initialCSS);

	if (!$('body').hasClass('general') && !$('body').hasClass('display-centre-single')) {
		$(window).on('scroll', function() {
			var scrollPos    = $(window).scrollTop(),
				windowHeight = $(window).height(),
				bottomMargin = Math.floor(windowHeight / 2);

			if (scrollPos > scrollCutoff) {
				if (loaderTop >= scrollCutoff) {
					bottomPadding = (Math.abs(parseInt($loader.css('margin-top'))) / 2) + $loader.outerHeight();
					if ($('body').hasClass('search')) bottomPadding += (Math.abs(parseInt($loader.css('margin-top'))));

					if (scrollPos > (bottomCutoff - windowHeight + bottomPadding)) {
						$loader.css({ position: 'absolute', top: 'auto', bottom: (Math.floor(scrollCutoff / 2) + bottomPadding) + 'px' });
					}
					else {
						$loader.css({ position: 'fixed', top: '50%', bottom: 'auto' });
					}
				}
				else $loader.css(initialCSS);
			}
			else $loader.css(initialCSS);
		});
		$(window).on('resize', function() {
			windowHeight = $(window).height();
		});
	}
}
function mobileView() {
	return $(window).outerWidth() < 768;
}
function eqHeight($elem){
	if (!$elem.length) return;

	if (!mobileView()) {
		var maxHeight = 0;
		$elem.outerHeight('auto');
		$elem.each(function(){
			var elemHeight = $(this).outerHeight();
			if ( maxHeight > elemHeight ) maxHeight = maxHeight;
			else maxHeight = elemHeight;
		});
		$elem.outerHeight(maxHeight);
	} else {
		setTimeout(function() {
			$elem.each(function(){
				$elem.outerHeight('auto');
			});
		}, 250);
	}
}
function fadeLoader(direction, callback, loader) {
	var $loader;
	if (typeof loader == 'object') $loader = loader;
	else if (typeof loader == 'string') $loader = $(loader);
	else $loader = $('.loader-panel');

	if (direction == 'in') {
		$loader.removeClass('stopped').show().css({ zIndex: 1001, visibility: 'visible' });
		loaderInit();
		$(window).trigger('scroll');
		$loader.fadeTo(400, 1);
	}
	else {
		$loader.fadeTo(400, 0, function() {
			$(this).delay(500).addClass('stopped').css({ zIndex: -1, visibility: 'hidden' }).hide();
		});
	}
	setTimeout(function() {
		Waypoint.refreshAll();
	}, 500);
	if (callback && typeof(callback) == 'function') callback();
}

function ini_svgPanZoom(selector) {
	var panEnabled = true;

	if ($(selector).parent().is(':hidden') == false) {
		if ($('html').width() < 768 || $('html').hasClass('tablet')) panEnabled = false;
		var panZoom = svgPanZoom(selector, {
			zoomEnabled: panEnabled,
			panEnabled: panEnabled,
			//customEventsHandler: pinchZoomEventsHandler,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			preventMouseEventsDefault: false,
			dblClickZoomEnabled: false,
			fit: 1,
			center: 1,
			// maxZoom: $('#svg1').data('max-zoom'),
			beforePan: function(oldPan, newPan) {
				var gutterWidth = 300,
					gutterHeight = 300;
				// Computed variables
				var sizes = this.getSizes(),
					leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
					rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
					topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
					bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
				customPan = {};
				customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
				customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
				return customPan;
			}
		});
		if ($('html').hasClass('mobile')) {
			if ($(selector).attr('data-storeys') != '1') {
				panZoom.updateBBox();
				panZoom.reset();
				var timeout = setTimeout(function() {
					panZoom.fit();
				}, 1000);
			}
			else {
				panZoom.reset();
			}
		}


		if (!$('body').hasClass('readybuilt')) {
			if ($(selector).attr('data-zoom').length > 0) {
				var zoomFactor = $(selector).attr('data-zoom');
				if (/^([0-9]{1})((\.)?([0-9]+))?$/.test(zoomFactor)) {
					panZoom.zoom(zoomFactor);
				}
			}
		}

		$('.design .ico-zoom-in').off('click');
		$('.design .ico-zoom-in').on('click', function () {
			console.log('click zoom-in');
			panZoom.zoomIn();
			return false;
		});
		$('.design .ico-zoom-out').off('click');
		$('.design .ico-zoom-out').on('click', function() {
			console.log('click zoom-out');
			panZoom.zoomOut();
			return false;
		});
		$('.design .design__inner-actions').on('click', 'a', function() {
			if ($(this).children('.ico-zoom-in').length > 0) {
				$('.design .ico-zoom-in').trigger('click');
				return false;
			}
			else if ($(this).children('.ico-zoom-out').length > 0) {
				$('.design .ico-zoom-out').trigger('click');
				return false;
			}
		});
		$('.design__select-design a').click(function(){
			//panZoom.setMaxZoom($(this).data('max-zoom'));
		});
		$('.design__image').css('visibility', 'visible');
	}
}

function rb_townhome_svgPanZoom(selector) {
	var panEnabled = true;
	if ($(selector).parent().is(':hidden') == false) {
		if ($('html').width() < 768 || $('html').hasClass('tablet')) panEnabled = false;

		var panZoom = svgPanZoom(selector, {
			zoomEnabled: panEnabled,
			panEnabled: panEnabled,
			//customEventsHandler: pinchZoomEventsHandler,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			preventMouseEventsDefault: false,
			dblClickZoomEnabled: false,
			fit: 1,
			center: 1,
			beforePan: function(oldPan, newPan) {
				var gutterWidth = 300,
					gutterHeight = 300;
				// Computed variables
				var sizes       = this.getSizes(),
					leftLimit   = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
					rightLimit  = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
					topLimit    = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
					bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
				customPan   = {};
				customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
				customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
				return customPan;
			}
		});
		if ($('html').hasClass('mobile')) {
			if ($(selector).attr('data-storeys') != '1') {
				panZoom.updateBBox();
				panZoom.reset();
				panZoom.fit();
			}
			else {
				panZoom.reset();
			}
		}
		else {
			panZoom.reset();
		}
	}
}

function rb_zoom_svgPanZoom(svgID) {
	var panEnabled = true;
	if ($('html').width() < 768 || $('html').hasClass('ipad')) panEnabled = false;
	var panZoom2 = svgPanZoom('#' + svgID, {
		zoomEnabled: panEnabled,
		panEnabled: panEnabled,
		customEventsHandler: pinchZoomEventsHandler,
		controlIconsEnabled: false,
		mouseWheelZoomEnabled: false,
		dblClickZoomEnabled: false,
		beforePan: function(oldPan, newPan) {
			if ($('html').width() < 768) {
				var gutterWidth = 100,
					gutterHeight = 100;
			}
			else {
				var gutterWidth = 600,
					gutterHeight = 600;
			}
			// Computed variables
			var sizes       = this.getSizes(),
				leftLimit   = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
				rightLimit  = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
				topLimit    = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
				bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
			customPan   = {};
			customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
			customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
			return customPan;
		}
	});
	panZoom2.reset();

	setTimeout(function() {
		$('#' + svgID).attr('data-reset', 'reset');
		// check to see if the SVG fits properly
		var svgContainer = document.getElementById(svgID);
		if (typeof svgContainer != 'undefined' && svgContainer != null) {
			var svgDoc         = svgContainer.contentDocument,
				$viewport      = $('g.svg-pan-zoom_viewport', svgDoc);
			if ($viewport.length == 0) $viewport = $('g[id^="viewport-"]', svgDoc);

			var viewportHeight = $viewport[0].getBoundingClientRect().height,
				viewportWidth  = $viewport[0].getBoundingClientRect().width,
				zoomHeight     = $('#' + svgID).outerHeight();
				zoomWidth      = $('#' + svgID).outerWidth();
			if (viewportHeight > zoomHeight || viewportWidth > zoomWidth) {
				$('#' + svgID).attr('data-reset', 'fit');
				panZoom2.updateBBox();
				panZoom2.fit();
			}
		}
	}, 1000);

	$('body.readybuilt .zoom-in-modal .ico-zoom-in').off('click');
	$('body.readybuilt .zoom-in-modal .ico-zoom-in').click(function(e){
		e.preventDefault();
		panZoom2.zoomIn();
	});
	$('body.readybuilt .zoom-in-modal .ico-zoom-out').off('click');
	$('body.readybuilt .zoom-in-modal .ico-zoom-out').click(function(e){
		e.preventDefault();
		panZoom2.zoomOut();
	});

	if ($('html').hasClass('edge')) {
		$('body.readybuilt .zoom-in-modal .center .right.block').css('visibility', 'visible');
	}
	else {
		if ($('body.readybuilt .zoom-in-modal').css('visibility') == 'visible') $('body.readybuilt .zoom-in-modal .center .right.block').css('visibility', 'visible');
	}
}

function window_width()
{
	  var myWidth = 0;
	  if( typeof( window.innerWidth ) == 'number' )
		myWidth = window.innerWidth;
	  else if ( (document.documentElement) && (document.documentElement.clientWidth) )
		myWidth = document.documentElement.clientWidth;
	  else if( (document.body) && (document.body.clientWidth) )
		myWidth = document.body.clientWidth;
	  return(myWidth);
}
////////////////////////////////////////////////
function window_height()
{
	 var myHeight = 0;
	  if( typeof( window.innerHeight ) == 'number' )
		myHeight = window.innerHeight;
	  else if ( (document.documentElement) && (document.documentElement.clientHeight) )
		myHeight = document.documentElement.clientHeight;
	  else if( document.body && (document.body.clientHeight) )
		myHeight = document.body.clientHeight;
	  return(myHeight);
}

function propertyItemSlider() {
	if ($('body').hasClass('new-homes')) {
		$('.property-item__slider').slick({
			autoplay: false,
			arrows: false,
			dots: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true
		});
		$('.property-item__dots').on('click', 'a', function () {
			var slideID       = $(this).attr('data-slide'),
				$gotoSlide    = $('#slide-' + slideID),
				slideNumber   = $gotoSlide.attr('data-slide-number'),
				$propertyDots = $(this).parents('ul.property-item__dots'),
				$slider       = $propertyDots.next('.property-item__slider');
			$slider.slick('slickGoTo', slideNumber);
			return false;
		});
		$('.property-item__slider').each(function() {
			var $thisSlider = $(this);
			$thisSlider.on('afterChange', function(slick, currentSlide) {
				var $thisSliderBody        = $thisSlider.parents('.property-item__body'),
					$thisSliderDots        = $thisSliderBody.children('.property-item__dots'),
					$thisSliderRoot        = $thisSlider.parents('.property.nh-property'),
					$currentSlide          = $thisSlider.find('.slick-current'),
					currentSlideID         = $currentSlide.attr('id').replace('slide-', ''),
					$currentSlideClickable = $currentSlide.children('.property-item__body-clickable'),
					$photoClickable        = $thisSliderBody.prev('.property-item__photo-clickable');
				// show an accurate representation of which floor plan is the currently displayed one
				$thisSliderDots.find('a.active').removeClass('active');
				$thisSliderDots.find('a#property-dot-' + currentSlideID).addClass('active');
				$thisSliderRoot.attr('data-href', $currentSlideClickable.attr('href'));
				// change the photo clickable href in case the user clicks on the photo
				$photoClickable.attr('href', $currentSlideClickable.attr('href'));
			});
		});
	}
	else {
		$('.property-item__slider').slick({
			autoplay: false,
			arrows: true,
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true
		});
	}
}

var pinchZoomEventsHandler;

    pinchZoomEventsHandler = {
      haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
    , init: function(options) {
        var instance = options.instance
          , initialScale = 1
          , pannedX = 0
          , pannedY = 0

        // Init Hammer
        // Listen only for pointer and touch events
        this.hammer = Hammer(options.svgElement, {
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
        })

        // Enable pinch
        this.hammer.get('pinch').set({enable: true})

        // Handle double tap
        this.hammer.on('doubletap', function(ev){
          instance.zoomIn()
        })

        // Handle pan
        this.hammer.on('panstart panmove', function(ev){
          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }

          // Pan only the difference
          instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
          pannedX = ev.deltaX
          pannedY = ev.deltaY
        })

        // Handle pinch
        this.hammer.on('pinchstart pinchmove', function(ev){
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoom(initialScale * ev.scale)
          }

          instance.zoom(initialScale * ev.scale)

        })

        // Prevent moving the page on some devices when panning over SVG
        options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
      }

    , destroy: function(){
        this.hammer.destroy()
      }
    };